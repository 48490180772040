import * as React from 'react';
import AtomSpinner from './atom-spinner';
import './loading-page.scss';

interface Props {
  message?: string;
}

const LoadingPage: React.FC<Props> = (props) => {
  return (
    <div>
      <div className="loading-screen">
        <div className="spinner-container">
          <AtomSpinner className="la-3x spinner" />
          <div className="spinner-caption">{props.message || 'Loading'}</div>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
