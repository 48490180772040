import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';
import DashboardProvider from '../data-fetcher/dashboard-provider';
import DashboardBuilder from '../dashboard-builder/dashboard-builder';
import {firebaseApp} from '../auth/firebase';
import {Dashboard} from '../dashboard-builder/types';

interface Props {}

const DashboardBuilderPage: React.FC<Props> = (props) => {
  const route = useRouteMatch<{id: string}>();
  const handleSave = React.useCallback(
    async (dashboard: Dashboard, name: string) => {
      await firebaseApp
        .firestore()
        .collection('dashboards')
        .doc(route.params.id)
        .update({
          serializedDashboard: JSON.stringify(dashboard),
          name,
        });
    },
    [route.params.id],
  );

  return (
    <div>
      <DashboardProvider dashboardId={route.params.id}>
        <DashboardBuilder onSave={handleSave} />
      </DashboardProvider>
    </div>
  );
};

export default DashboardBuilderPage;
