import * as React from 'react';
import {Button} from 'reactstrap';
import {firebaseApp} from './firebase';
import {AugmentedUser} from './use-current-user';
import {Link, useHistory} from 'react-router-dom';

interface Props {
  user: AugmentedUser;
}

const UserControls: React.FC<Props> = (props) => {
  const {user} = props;
  const history = useHistory();
  const logOut = React.useCallback(async () => {
    await firebaseApp.auth().signOut();
    history.push('/');
  }, [history]);
  return (
    <div className="user-controls">
      <Button disabled color="link">
        {user.email}
      </Button>
      {user.admin && <Link to="/">Admin</Link>}
      <Button onClick={logOut} color="link">
        Log out
      </Button>
    </div>
  );
};

export default UserControls;
