import * as React from 'react';
import {WidgetProps} from '../types';
import Widget from '../widget';
import {TextWidget} from './text-widget';
import './text-widget.scss';

const TextComponent: React.SFC<WidgetProps<TextWidget>> = (props) => {
  return (
    <Widget
      widget={props.widget}
      className="text-widget"
      title={props.widget.settings.title}
      onEdit={props.onEdit}
      readonly={props.readonly}
      onDelete={props.onDelete}
    />
  );
};

export default TextComponent;
