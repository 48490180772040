import * as React from 'react';
import FilterProvider from '../filters/filter-provider';
import useDashboard, {DashboardRecord} from '../auth/use-dashboard';
import DataProvider from './data-provider';
import {Dashboard} from '../dashboard-builder/types';
import LoadingPage from '../loading/loading-page';

export const DashboardContext = React.createContext<Dashboard | null>(null);
export const DashboardRecordContext = React.createContext<DashboardRecord | null>(
  null,
);

const DashboardProvider: React.FC<{dashboardId: string}> = (props) => {
  const [data, isLoading, hasError] = useDashboard(props.dashboardId);

  const parsedDashboard = React.useMemo(() => {
    if (!data) {
      return null;
    }
    return JSON.parse(data.serializedDashboard || '{}') as Dashboard;
  }, [data]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (hasError || !data) {
    return <span>There was an error loading data</span>;
  }

  if (!data.processedSheetId) {
    return <div>Please select a data file before opening this dashboard.</div>;
  }

  return (
    <DashboardRecordContext.Provider value={data}>
      <DashboardContext.Provider value={parsedDashboard}>
        <DataProvider processedSheetId={data.processedSheetId}>
          <FilterProvider>{props.children}</FilterProvider>
        </DataProvider>
      </DashboardContext.Provider>
    </DashboardRecordContext.Provider>
  );
};

export default DashboardProvider;
