import * as React from 'react';
import Select from 'react-select';
import useCurrentUser from './auth/use-current-user';
import {DashboardRecord} from './auth/use-dashboard';
import {firebaseApp} from './auth/firebase';
import {useHistory, useRouteMatch} from 'react-router-dom';

interface Props {
  hideIfOne?: boolean;
}

const DashboardSelector: React.FC<Props> = (props) => {
  const history = useHistory();
  const route = useRouteMatch<{id?: string}>('/dashboards/:id');
  const {user} = useCurrentUser();
  const [dashboards, setDashboards] = React.useState<
    Array<DashboardRecord & {id: string}>
  >([]);
  const [dashboardsLoading, setDashboardsLoading] = React.useState(true);
  React.useEffect(() => {
    if (!user) return;
    setDashboardsLoading(true);
    return firebaseApp
      .firestore()
      .collection('dashboards')
      .where('userIds', 'array-contains', user.uid)
      .onSnapshot((res) => {
        setDashboards(
          res.docs.map(
            (d) => ({...d.data(), id: d.id} as DashboardRecord & {id: string}),
          ),
        );
        setDashboardsLoading(false);
      });
  }, [user]);
  return (
    <div>
      {props.hideIfOne &&
      (dashboardsLoading || dashboards.length <= 1) ? null : (
        <Select
          options={dashboards.map((d) => ({
            label: d.name,
            value: d.id,
            disabled: route?.params.id === d.id,
          }))}
          isOptionDisabled={(o) => o.disabled}
          placeholder={dashboardsLoading ? 'Loading...' : 'Choose a dashboard'}
          value={null}
          onChange={(val) => {
            // @ts-ignore
            if (val && val.value) {
              // @ts-ignore
              history.push(`/dashboards/${val.value}`);
            }
          }}
        />
      )}
    </div>
  );
};

export default DashboardSelector;
