import isArrayBuffer from 'lodash/isArrayBuffer';
import * as React from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import uuid from 'uuid';
import {firebaseApp} from '../auth/firebase';
import {DashboardRecord} from '../auth/use-dashboard';
import {ProcessedSheet, readBuffer} from '../data-ingest/xlsx-reader';
import AtomSpinner from '../loading/atom-spinner';
import isDashboardCompatibleWithNewVariables from '../widgets/is-dashboard-compatible-with-new-variables';

interface Props {
  dashboardRecord: (DashboardRecord & {id: string}) | null;
  onClose: () => void;
}

export interface DataRecord {
  fileName: string;
  lastModified: string;
  storagePath: string;
  uploadedAt: string;
  serializedProcessedData: string;
  serializedVersion: string;
  dashboardId: string;
}

// Currently this does nothing, but it can be used in the future for migrations.
const SERIALZIED_VERSION = '1';

const UploadModal: React.FC<Props> = (props) => {
  const {dashboardRecord, onClose} = props;
  const [details, setDetails] = React.useState<DataRecord | null>(null);
  const [detailsCleanup, setDetailsCleanup] = React.useState<
    (() => void) | null
  >(null);
  const [detailsLoading, setDetailsLoading] = React.useState(false);
  React.useEffect(() => {
    if (!dashboardRecord || !dashboardRecord.processedSheetId)
      return setDetails(null);
    setDetailsLoading(true);
    const cleanUp = firebaseApp
      .firestore()
      .doc(`processedSheets/${dashboardRecord.processedSheetId}`)
      .onSnapshot((ss) => {
        setDetailsLoading(false);
        setDetails(ss.data() as DataRecord);
      });
    setDetailsCleanup(() => () => {
      cleanUp();
    });
    return cleanUp;
  }, [dashboardRecord, setDetailsCleanup]);
  const [file, setFile] = React.useState<File | null>(null);
  const [parsedFile, setParsedFile] = React.useState<ProcessedSheet | null>(
    null,
  );
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isParsing, setIsParsing] = React.useState(false);
  const dashboard = React.useMemo(() => {
    if (dashboardRecord && dashboardRecord.serializedDashboard) {
      return JSON.parse(dashboardRecord.serializedDashboard);
    }
  }, [dashboardRecord]);
  const compatibilityErrors = React.useMemo<true | string[]>(() => {
    if (!dashboard || !parsedFile) {
      return true;
    }
    return isDashboardCompatibleWithNewVariables(dashboard, parsedFile[0]);
  }, [dashboard, parsedFile]);
  const parseFile = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!file) return;
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      const fr = new FileReader();
      fr.onload = function(e) {
        if (e.target && isArrayBuffer(e.target.result)) {
          setIsParsing(false);
          const data = new Uint8Array(e.target.result);
          const parsedSheet = readBuffer(data);
          setParsedFile(parsedSheet);
        }
      };
      fr.onerror = function(e) {
        setIsParsing(false);
        console.error('Error reading file', e);
      };
      setIsParsing(true);
      fr.readAsArrayBuffer(file);
    },
    [file, setIsParsing],
  );

  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState<string | null>(null);
  const handleClose = React.useCallback(() => {
    setIsUploading(false);
    setIsParsing(false);
    setParsedFile(null);
    setIsParsing(false);
    setUploadError(null);
    setDetailsLoading(false);
    setDetails(null);
    if (detailsCleanup) {
      detailsCleanup();
    }
    setDetailsCleanup(null);
    onClose();
  }, [onClose, detailsCleanup]);
  const uploadFile = React.useCallback(() => {
    if (!file || !parsedFile || !dashboardRecord) {
      console.error('uploadFile invoked without a file and parsedFile');
      return Promise.resolve();
    }

    const id = uuid.v4();
    const path = `dashboard-data/excel-upload-${id}.xlsx`;

    // Upload file to firebase storage
    const fileRef = firebaseApp.storage().ref(path);
    const uploadPromise = fileRef.put(file);

    // Upload data to firestore
    const dbRef = firebaseApp.firestore().collection(`processedSheets`);
    const record: DataRecord = {
      fileName: file.name,
      lastModified: new Date(file.lastModified).toISOString(),
      storagePath: path,
      uploadedAt: new Date().toISOString(),
      serializedProcessedData: JSON.stringify(parsedFile),
      serializedVersion: SERIALZIED_VERSION,
      dashboardId: dashboardRecord.id,
    };

    const dbPromise = dbRef.add(record).then((r) => {
      // Update old databaseRecord to use new processed sheet
      firebaseApp
        .firestore()
        .collection('dashboards')
        .doc(dashboardRecord.id)
        .update({
          processedSheetId: r.id,
        });
    });

    setIsUploading(true);
    setUploadError(null);
    return Promise.all([uploadPromise, dbPromise])
      .then(() => {
        setIsUploading(false);
        handleClose();
      })
      .catch((err) => {
        setIsUploading(false);
        setUploadError(err.message);
        console.error('Error uploading file', err);
      });
  }, [file, parsedFile, dashboardRecord, setUploadError, handleClose]);

  const modalState = isParsing ? 'PARSING' : parsedFile ? 'PARSED' : 'UPLOAD';
  return (
    <Modal isOpen={!!dashboardRecord} toggle={handleClose}>
      <ModalHeader>Upload</ModalHeader>
      {modalState === 'UPLOAD' ? (
        <Form onSubmit={parseFile}>
          <ModalBody>
            {detailsLoading ? (
              <div>Loading file details...</div>
            ) : details ? (
              <div className="file-details">
                <dl>
                  <dt>File name</dt>
                  <dd>{details.fileName}</dd>
                  <dt>Last modified</dt>
                  <dd>{new Date(details.lastModified).toLocaleDateString()}</dd>
                  <dt>Uploaded at</dt>
                  <dd>{new Date(details.uploadedAt).toLocaleString()}</dd>
                </dl>
              </div>
            ) : (
              <div>No data file has been uploaded for this dashboard</div>
            )}
            <hr />
            <h4>Upload new file</h4>
            <FormGroup>
              <Label htmlFor="file" className="sr-only">
                XLSX File
              </Label>
              <Input
                type="file"
                id="file"
                accept=".xlsx"
                innerRef={fileInputRef}
                onChange={(e) =>
                  setFile(e.target.files ? e.target.files[0] : null)
                }
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Check file</Button>
          </ModalFooter>
        </Form>
      ) : modalState === 'PARSING' ? (
        <ModalBody>
          <AtomSpinner className="la-2x mx-auto my-3" />
        </ModalBody>
      ) : modalState === 'PARSED' ? (
        <>
          <ModalBody>
            {uploadError && (
              <Alert color="danger">
                Error uploading document: {uploadError}
              </Alert>
            )}
            {dashboard ? (
              compatibilityErrors === true ? (
                <div>New data is compatible with existing dashboard!</div>
              ) : (
                <div>
                  <p>
                    The new data file is not compatible with the existing
                    dashboard. Please resolve the following errors before
                    uploading this file.
                  </p>
                  <ListGroup>
                    {compatibilityErrors.map((e, i) => (
                      <ListGroupItem key={i}>{e}</ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              )
            ) : (
              <div>Click below to confirm upload</div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={compatibilityErrors !== true || isUploading}
              onClick={uploadFile}
            >
              Upload
            </Button>
          </ModalFooter>
        </>
      ) : (
        'Unknown modal state'
      )}
    </Modal>
  );
};

export default UploadModal;
