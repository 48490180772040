import * as React from 'react';
import {SliderWidget} from './slider-widget';
import {Formik, Form, Field} from 'formik';
import {ModalBody, ModalFooter, FormGroup, Label, Input} from 'reactstrap';

interface SliderSettingFormValues {
  title: string;
}

interface Props {
  widget: SliderWidget;
  onSubmit: (newSettings: SliderSettingFormValues) => void;
  controls: React.ReactNode;
}

const SliderSettingsForm: React.FC<Props> = (props) => {
  const initialValues: SliderSettingFormValues = {
    title: props.widget.settings.title,
  };

  return (
    <Formik<SliderSettingFormValues>
      initialValues={initialValues}
      onSubmit={(values) => props.onSubmit(values)}
    >
      {(formikBag) => {
        return (
          <Form translate="">
            <ModalBody>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Field name="title" as={Input} />
              </FormGroup>
            </ModalBody>
            <ModalFooter>{props.controls}</ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SliderSettingsForm;
