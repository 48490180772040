import {Variable} from '../../data-ingest/xlsx-reader';
import isNumber from 'lodash/isNumber';
import {BaseWidget, BaseWidgetSettings} from '../types';
import startCase from 'lodash/startCase';

export interface SliderWidgetSettings extends BaseWidgetSettings {
  variable: Variable;
}

export interface SliderWidget extends BaseWidget<SliderWidgetSettings> {
  type: 'slider';
}

export const canCreateSlider: (variable: Variable) => boolean = (
  variable: Variable,
) => {
  return (
    variable.uniqueValues.every((v) => isNumber(v)) &&
    variable.uniqueValues.length > 2
  );
};

export const createSliderWidget = (
  variable: Variable,
  nextLayout: SliderWidget['settings']['layout'],
): SliderWidget => {
  return {
    type: 'slider' as const,
    settings: {
      title: startCase(variable.name),
      variable,
      layout: {
        ...nextLayout,
        w: 2,
        h: 6,
      },
    },
  };
};
