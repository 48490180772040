import {Widget} from './types';
import {Variable} from '../data-ingest/xlsx-reader';
import {canCreatePie} from './pie/pie-widget';
import {canCreateList} from './list/list-widget';
import {canCreateSlider} from './slider/slider-widget';
import {Dashboard} from '../dashboard-builder/types';

const findVariable = (
  widget: {settings: {variable: Variable}},
  variables: Variable[],
): Variable | null => {
  return (
    variables.find((v) => v.name === widget.settings.variable.name) || null
  );
};

type SingleVariableWidget = Widget & {settings: {variable: Variable}};
const validForSingleVariable = (
  canCreate: (variable: Variable) => boolean,
  widget: SingleVariableWidget,
  variables: Variable[],
): string | true => {
  const variable = findVariable(widget, variables);
  if (!variable) {
    return `Variable ${widget.settings.variable.name} which was used in a ${widget.type} widget is missing. Either remove the widget from the dashboard or add the variable to the Excel sheet.`;
  }
  const can = canCreate(variable);
  if (!can) {
    return `Variable ${widget.settings.variable.name} which was used in a ${widget.type} widget can no longer be used because the types of values have changed`;
  }
  return can;
};

const isValidWithNewVariables = (
  widget: Widget,
  variables: Variable[],
): string | true => {
  switch (widget.type) {
    case 'pie':
      return validForSingleVariable(canCreatePie, widget, variables);
    case 'list':
      return validForSingleVariable(canCreateList, widget, variables);
    case 'slider':
      return validForSingleVariable(canCreateSlider, widget, variables);
    case 'stackedbars':
      return true;
    case 'text':
      return true;
    default:
      console.error(`Unknown widget type ${widget.type}`);
      return true;
  }
};

const isDashboardCompatibleWithNewVariables = (
  dashboard: Dashboard,
  variables: Variable[],
): string[] | true => {
  const results = dashboard.widgets.map((w) =>
    isValidWithNewVariables(w, variables),
  );
  const errors = results.filter((r) => r !== true) as string[];
  return errors.length === 0 ? true : errors;
};

export default isDashboardCompatibleWithNewVariables;
