import React from 'react';
import './main.scss';
import './App.scss';
import LoginPage from './auth/login-page';
import useCurrentUser from './auth/use-current-user';
import UserControls from './auth/user-controls';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import logo from './logo.png';
import DashboardPage from './pages/dashboard-page';
import DashboardSelectPage from './pages/dashboard-select-page';
import AdminPage from './pages/admin-page';
import DashboardBuilderPage from './pages/dashboard-builder-page';
import DashboardSelector from './dashboard-selector';

const App: React.FC<{}> = (props) => {
  const {user} = useCurrentUser();

  return !user ? (
    <div className="app">
      <main className="main">
        <LoginPage />
      </main>
    </div>
  ) : (
    <Router>
      <div className="app">
        <div className="nav-bar justify-content-between align-items-baseline">
          <div className="logo-container">
            <Link to="/">
              <img src={logo} className="logo" alt="CQ Insights Logo" />
            </Link>
          </div>
          <div style={{width: '400px'}}>
            {!user.admin && <DashboardSelector hideIfOne />}
          </div>
          <UserControls user={user} />
        </div>
        <main className="main">
          <Switch>
            {user.admin && (
              <Route path="/dashboards/:id/edit">
                <DashboardBuilderPage />
              </Route>
            )}
            <Route path="/dashboards/:id" exact>
              <DashboardPage />
            </Route>
            <Route path="/">
              {user.admin ? <AdminPage /> : <DashboardSelectPage />}
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
};

export default App;
