import * as React from 'react';
import './atom-spinner.scss';

interface Props {
  className?: string;
}

const AtomSpinner: React.FC<Props> = (props) => {
  return (
    <div className={`la-ball-atom ${props.className ? props.className : ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default AtomSpinner;
