import * as React from 'react';
import html2canvas from 'html2canvas';
import {saveAs} from 'file-saver';
import ReactDOM from 'react-dom';

const componentSnapshot = (
  node: React.RefObject<HTMLElement>,
  fileName: string,
  backgroundColor?: string,
) => {
  const element = ReactDOM.findDOMNode(node.current) as HTMLElement;
  if (!element) {
    return;
  }
  return html2canvas(element, {
    backgroundColor: backgroundColor,
    scrollY: -window.scrollY,
    useCORS: true,
    onclone: (document) => {
      // Svgs with % width/height get messed up
      const svgs = document.querySelectorAll('svg');
      svgs.forEach((svg) => {
        svg.setAttribute('width', `${svg.clientWidth}px`);
        svg.setAttribute('height', `${svg.clientHeight}px`);
      });

      document.querySelectorAll('.remove-from-snapshot').forEach((el) => {
        el.remove();
      });
    },
  }).then((canvas) => {
    saveAs(canvas.toDataURL('image/png', 1.0), fileName);
  });
};

export default componentSnapshot;
