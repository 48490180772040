import * as React from 'react';
import {ArrayHelpers} from 'formik';
import {Variable} from '../../data-ingest/xlsx-reader';
import {ListGroupItem} from 'reactstrap';
import {
  DropResult,
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  Draggable,
  DraggableProvided,
} from 'react-beautiful-dnd';
import uuid from 'uuid';
import startCase from 'lodash/startCase';

interface Props {
  arrayHelpers: ArrayHelpers;
  barVariables: Variable[];
}

const BarVariablesList: React.FC<Props> = (props) => {
  const {barVariables, arrayHelpers} = props;
  const [id] = React.useState(() => `bvl-${uuid.v4()}`);
  const handleDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination) {
        arrayHelpers.move(result.source.index, result.destination.index);
      }
    },
    [arrayHelpers],
  );
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`droppable-${id}`} direction="horizontal">
        {(provided: DroppableProvided, ss: DroppableStateSnapshot) => (
          <ul
            className="list-group list-group-horizontal"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {barVariables.length > 0 ? (
              barVariables.map((v, i) => (
                <Draggable
                  key={v.name}
                  draggableId={`draggable-${v.name.replace(/\s/g, '')}-${id}`}
                  index={i}
                >
                  {(draggableProv: DraggableProvided) => (
                    <div
                      className="list-group-item"
                      ref={draggableProv.innerRef}
                      {...draggableProv.draggableProps}
                      {...draggableProv.dragHandleProps}
                    >
                      {startCase(v.name)}
                    </div>
                  )}
                </Draggable>
              ))
            ) : (
              <ListGroupItem>No variables</ListGroupItem>
            )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default BarVariablesList;
