import * as React from 'react';
import DashboardSelector from '../dashboard-selector';
import {Container} from 'reactstrap';

interface Props {}

const DashboardSelectPage: React.FC<Props> = (props) => {
  return (
    <Container>
      <h1>Welcome</h1>
      <DashboardSelector />
    </Container>
  );
};

export default DashboardSelectPage;
