import {FieldInputProps} from 'formik';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import * as React from 'react';
import {FormFeedback, FormGroup, Input, InputProps, Label} from 'reactstrap';
import * as uuid from 'uuid';

interface Props extends InputProps {
  label?: string;
  labelClassName?: string;
  field: FieldInputProps<string>;
  renderInput?: (inputProps: InputProps) => React.ReactNode;
  form: any; // not sure what type this should be in a generic setting
}

const FieldGroup: React.FC<Props> = (props) => {
  const {
    label,
    renderInput,
    field,
    form,
    labelClassName,
    ...inputProps
  } = props;
  const [id] = React.useState(() => `fg-${uuid.v4()}`);
  const touched: boolean = get(props.form.touched, props.field.name);
  const error = get(props.form.errors, props.field.name);
  const showError: boolean = !!get(props.form.errors, props.field.name);
  const allInputProps = {
    id: id,
    valid: touched ? !showError : undefined,
    invalid: touched ? showError : undefined,
    ...props.field,
    ...inputProps,
  };
  return (
    <FormGroup>
      <Label htmlFor={id} className={labelClassName}>
        {props.label || props.field.name}
      </Label>
      {renderInput ? renderInput(allInputProps) : <Input {...allInputProps} />}
      <FormFeedback>
        {isString(error)
          ? error
          : isObject(error)
          ? Object.values(error).map((e, i) => (
              <span key={i}>{JSON.stringify(e)}</span>
            ))
          : 'Invalid'}
      </FormFeedback>
    </FormGroup>
  );
};

export default FieldGroup;
