import * as React from 'react';
import {FieldArray, Field, ArrayHelpers} from 'formik';
import {WidgetValue} from './types';
import './value-fields.scss';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import {ColorPickerButtonField} from '../color-picker-button';
import {
  DragDropContext,
  DraggableProvided,
  DroppableProvided,
  DroppableStateSnapshot,
  Draggable,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

interface Props {
  values: WidgetValue[];
  setFieldValue: (field: string, value: string) => void;
}

const ValueFields: React.FC<Props> = (props) => {
  const handleDragEnd = React.useCallback(
    (result: DropResult, arrayHelpers: ArrayHelpers) => {
      if (result.destination) {
        arrayHelpers.move(result.source.index, result.destination.index);
      }
    },
    [],
  );

  return (
    <FieldArray
      name="values"
      render={(arrayHelpers) => (
        <div className="value-fields">
          <DragDropContext
            onDragEnd={(result) => handleDragEnd(result, arrayHelpers)}
          >
            <Droppable droppableId="value-droppable">
              {(
                provided: DroppableProvided,
                snapshot: DroppableStateSnapshot,
              ) => (
                <table
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`fields-drop-area ${
                    snapshot.isDraggingOver ? 'hover' : ''
                  }`}
                >
                  <thead>
                    <th />
                    <th>Value</th>
                    <th>Label</th>
                  </thead>
                  <tbody>
                    {props.values.map((v, i) => (
                      <Draggable key={v.key} draggableId={`${v.key}`} index={i}>
                        {(
                          draggableProv: DraggableProvided,
                          draggableSs: DraggableStateSnapshot,
                        ) => (
                          <tr
                            key={i}
                            className={`field ${
                              draggableSs.isDragging ? 'dragging' : ''
                            }`}
                            ref={draggableProv.innerRef}
                            {...draggableProv.draggableProps}
                          >
                            <td
                              className="drag-handle"
                              {...draggableProv.dragHandleProps}
                            >
                              <span />
                            </td>
                            <td className="variable-name">{v.key}</td>
                            <td>
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <Field
                                    name={`values.${i}.color`}
                                    as={ColorPickerButtonField}
                                    setFieldValue={props.setFieldValue}
                                  />
                                </InputGroupAddon>
                                <Field name={`values.${i}.label`} as={Input} />
                              </InputGroup>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                  {provided.placeholder}
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    />
  );
};

export default ValueFields;
