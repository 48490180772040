import * as React from 'react';
import {User} from 'firebase';
import {firebaseApp} from './firebase';

export interface DbUser {
  id: string;
  clientId?: string;
  admin?: boolean;
  serializedDashboard?: string;
}

export type AugmentedUser = DbUser & User;
interface CurrentUserHook {
  user: AugmentedUser | null;
}

const useCurrentUser = (): CurrentUserHook => {
  const [user, setUser] = React.useState<AugmentedUser | null>(() => {
    const user = localStorage.getItem('user');
    if (user) {
      // This is potentially an issue because user is not just a plain object.
      // If any of its class features are accessed before it is updated with onAuthStateChanged, there will be bugs.
      // Without this, the login screen will flash even when the user is logged in.
      return JSON.parse(user);
    }
  });

  React.useEffect(() => {
    return firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        firebaseApp
          .firestore()
          .doc(`users/${user.uid}`)
          .get()
          .then((doc) => {
            const userData = {...(doc.data() as DbUser), id: doc.id};
            const mergedUser = {
              ...user,
              ...userData,
            };
            localStorage.setItem(
              'user',
              JSON.stringify({...user.toJSON(), ...userData}),
            );
            setUser(mergedUser);
          });
      } else {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(null));
      }
    });
  }, []);
  return {user};
};

export default useCurrentUser;
