import {Field, Formik, FormikHelpers} from 'formik';
import * as React from 'react';
import {Button, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {firebaseApp} from './auth/firebase';
import CheckFieldGroup from './check-field-group';
import FieldGroup from './field-group';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  initialValues: EditUserValues | null;
  id: string | null;
}

interface EditUserValues {
  name: string;
  admin: boolean;
}

const EditUserModal: React.FC<Props> = (props) => {
  const {toggle, id} = props;
  const [showDeleteInstructions, setShowDeleteInstructions] = React.useState(
    false,
  );

  const handleSubmit = React.useCallback(
    (values: EditUserValues, formHelpers: FormikHelpers<any>) => {
      (async () => {
        if (!id) return;
        await firebaseApp
          .firestore()
          .collection('users')
          .doc(id)
          .update({clientId: values.name, admin: values.admin});
        formHelpers.resetForm();
        formHelpers.setSubmitting(false);
        toggle();
      })();
    },
    [toggle, id],
  );

  const handleDelete = React.useCallback(async () => {
    if (!id) return;
    // eslint-disable-next-line
    if (!confirm('Are you sure you want to delete this user?')) {
      return;
    }
    // Remove userid from dashboards
    const dashboards = await firebaseApp
      .firestore()
      .collection('dashboards')
      .where('userIds', 'array-contains', id)
      .get();
    await Promise.all(
      dashboards.docs.map((d) =>
        firebaseApp
          .firestore()
          .collection('dashboards')
          .doc(d.id)
          .update({
            userIds: d
              .data()
              .userIds.filter((existingId: string) => existingId !== id),
          }),
      ),
    );

    // Remove db user
    await firebaseApp
      .firestore()
      .collection('users')
      .doc(id)
      .delete();
    setShowDeleteInstructions(true);
  }, [id, setShowDeleteInstructions]);

  if (showDeleteInstructions) {
    return (
      <Modal isOpen={!!props.isOpen} toggle={props.toggle}>
        <ModalBody>
          <p>This user no longer has access to any dashboards.</p>
          <p>
            To finish deleting the user, you need to log into the Firebase admin
            panel, go to the Authentication section, and delete the user's
            account. The user's uid is {id}.
          </p>
          <Button
            onClick={() => {
              setShowDeleteInstructions(false);
              toggle();
            }}
            color="primary"
          >
            Close
          </Button>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <Modal isOpen={!!props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit User</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={props.initialValues || {name: '', admin: false}}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <form onSubmit={form.handleSubmit}>
              <Field component={FieldGroup} name="name" label="Name" />
              <Field component={CheckFieldGroup} name="admin" label="Admin?" />
              <Button
                color="primary"
                type="submit"
                disabled={form.isSubmitting}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
        <Button className="mt-3" color="danger" onClick={handleDelete}>
          Delete user
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default EditUserModal;
