import * as React from 'react';
import {Button, Modal, ModalHeader} from 'reactstrap';
import {Widget} from '../widgets/types';
import TextSettingsForm from '../widgets/text/text-settings-form';
import ValueSettingsForm from '../widgets/value/value-settings-form';
import PieSettingsForm from '../widgets/pie/pie-settings-form';
import ListSettingsForm from '../widgets/list/list-settings-form';
import SliderSettingsForm from '../widgets/slider/slider-settings-form';
import StackedBarsSettingsForm from '../widgets/stacked-bars/stacked-bars-settings-form';
import {Variable} from '../data-ingest/xlsx-reader';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  widget: Widget | null;
  updateWidget: (widget: Widget) => void;
  variables: Variable[];
}

const WidgetSettingsModal: React.FC<Props> = (props) => {
  const {isOpen, toggle, widget, updateWidget} = props;

  const handleSubmit = React.useCallback(
    (values: Partial<Widget['settings']>) => {
      if (!widget || !values) {
        return;
      }
      // @ts-ignore
      updateWidget({
        ...widget,
        settings: {
          ...widget.settings,
          ...values,
        },
      });
      toggle();
    },
    [updateWidget, widget, toggle],
  );

  if (!widget) {
    return <div />;
  }

  const controls = (
    <>
      <Button color="link" onClick={toggle}>
        Cancel
      </Button>
      <Button color="primary" type="submit">
        Submit
      </Button>
    </>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="widget-settings-modal">
      <ModalHeader toggle={toggle}>Settings</ModalHeader>
      {widget.type === 'text' ? (
        <TextSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
        />
      ) : widget.type === 'value' ? (
        <ValueSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
        />
      ) : widget.type === 'slider' ? (
        <SliderSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
        />
      ) : widget.type === 'pie' ? (
        <PieSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
        />
      ) : widget.type === 'stackedbars' ? (
        <StackedBarsSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
          variables={props.variables}
        />
      ) : widget.type === 'list' ? (
        <ListSettingsForm
          widget={widget}
          onSubmit={handleSubmit}
          controls={controls}
        />
      ) : (
        `Unknown widget type ${(widget as Widget).type}`
      )}
    </Modal>
  );
};

export default WidgetSettingsModal;
