import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/firestore';

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDbgVpFx381ZFe54Rx1ZFaaLoftDWY0r3E',
  projectId: 'cq-insights',
  storageBucket: 'gs://cq-insights.appspot.com',
});

// Creating users automatically signs in as new user, make it annoying for admins to add new users.
// This workaround is from here: https://stackoverflow.com/questions/37517208/firebase-kicks-out-current-user/38013551#38013551
export const firebaseAppForCreatingUsers = firebase.initializeApp(
  {
    apiKey: 'AIzaSyDbgVpFx381ZFe54Rx1ZFaaLoftDWY0r3E',
    projectId: 'cq-insights',
    storageBucket: 'gs://cq-insights.appspot.com',
  },
  'forCreatingUsers',
);
