import {Variable} from '../../data-ingest/xlsx-reader';
import {BaseWidgetSettings, BaseWidget} from '../types';
import isNumber from 'lodash/isNumber';
import mean from 'lodash/mean';
import startCase from 'lodash/startCase';

export interface ValueWidgetSettings extends BaseWidgetSettings {
  aggregator: 'mean' | 'min' | 'max';
  variable: Variable;
}

export const aggregators = [
  {type: 'mean', label: 'Average', fn: (data: number[]) => mean(data)},
  {type: 'min', label: 'Minimum', fn: (data: number[]) => Math.min(...data)},
  {type: 'max', label: 'Maximum', fn: (data: number[]) => Math.max(...data)},
];

export interface ValueWidget extends BaseWidget<ValueWidgetSettings> {
  type: 'value';
}

export const canCreateValue: (variable: Variable) => boolean = (
  variable: Variable,
) => {
  return variable.uniqueValues.every((v) => isNumber(v));
};

export const createValueWidget = (
  variable: Variable,
  nextLayout: ValueWidget['settings']['layout'],
): ValueWidget => {
  return {
    type: 'value' as const,
    settings: {
      title: startCase(variable.name),
      variable,
      aggregator: 'mean',
      layout: {
        ...nextLayout,
        w: 2,
        h: 6,
      },
    },
  };
};
