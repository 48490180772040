import * as React from 'react';
import {Button, Tooltip} from 'reactstrap';
import * as uuid from 'uuid';

interface Props {
  onClick: () => void;
  icon: React.ReactChild;
  label: string;
}

const WidgetButton: React.FC<Props> = (props) => {
  const [id] = React.useState(() => `wb-${uuid.v4()}`);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggleToolTip = React.useCallback(() => setTooltipOpen(!tooltipOpen), [
    setTooltipOpen,
    tooltipOpen,
  ]);
  const openTooltip = React.useCallback(() => setTooltipOpen(true), [
    setTooltipOpen,
  ]);
  return (
    <>
      <Button
        onClick={props.onClick}
        outline
        color="link"
        size="sm"
        id={id}
        onMouseEnter={openTooltip}
      >
        {props.icon}
        <span className="sr-only">{props.label}</span>
      </Button>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={id}
        toggle={toggleToolTip}
      >
        {props.label}
      </Tooltip>
    </>
  );
};

export default WidgetButton;
