import * as React from 'react';
import {MergedData, WidgetProps} from '../types';
import {AddFilterContext, NO_DATA_KEY} from '../../filters/filter-provider';
import StackedBar from '../stacked-bar';
import Widget from '../widget';
import {ListWidget} from './list-widget';
import './list-widget.scss';
import tinycolor from 'tinycolor2';

interface ListMergedData extends MergedData {
  valueFullData: number;
  filteredLength: number;
  fullLength: number;
}
const ListComponent: React.SFC<WidgetProps<ListWidget>> = (props) => {
  const {widget} = props;
  const {settings} = widget;
  const addFilter = React.useContext(AddFilterContext);
  const handleClick = React.useCallback(
    (value: string | number, label: string) => {
      addFilter({
        variable: settings.variable,
        variableTitle: settings.title,
        values: [value],
        type: 'MATCH',
        labelMap: {
          [value]: label,
        },
      });
    },
    [addFilter, settings.variable, settings.title],
  );

  const filteredData = props.filteredData[1];
  const data = props.data[1];

  const listData = React.useMemo<ListMergedData[]>(() => {
    const withValues = settings.values.map((v) => ({
      ...v,
      value: filteredData.filter((r) => r[settings.variable.name] === v.key)
        .length,
      valueFullData: data.filter((r) => r[settings.variable.name] === v.key)
        .length,
    }));
    const missings = filteredData.filter(
      (r) => r[settings.variable.name] === undefined,
    );
    const missingsFullData = data.filter(
      (r) => r[settings.variable.name] === undefined,
    );
    if (missingsFullData.length > 0) {
      withValues.push({
        value: missings.length,
        valueFullData: missingsFullData.length,
        key: NO_DATA_KEY,
        color: '#333',
        label: 'No Data',
      });
    }
    const longestBar = Math.max(...withValues.map((v) => v.valueFullData));
    return withValues.map((v) => ({
      ...v,
      filteredLength: (v.value / longestBar) * 100,
      fullLength: (v.valueFullData / longestBar) * 100,
    }));
  }, [settings.values, filteredData, data, settings.variable]);

  return (
    <Widget
      widget={widget}
      title={settings.title}
      className="list-widget"
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      readonly={props.readonly}
      allowSnapshot
    >
      <table className="list-table">
        <tbody>
          {listData.map((v) => (
            <tr
              onClick={
                props.readonly ? () => handleClick(v.key, v.label) : undefined
              }
              key={v.key}
            >
              <td className="list-label-cell">
                <div className="max-width-hack">{v.label}</div>
              </td>
              <td className="list-bar-cell">
                <StackedBar
                  stacks={[
                    {length: v.filteredLength, color: v.color},
                    {
                      length: v.fullLength - v.filteredLength,
                      color: tinycolor(v.color)
                        .setAlpha(0.2)
                        .toString(),
                    },
                  ]}
                />
              </td>
              <td className="list-value-cell">{v.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Widget>
  );
};

export default ListComponent;
