import * as React from 'react';
import {WidgetProps} from '../types';
import {AddFilterContext, FilterContext} from '../../filters/filter-provider';
import InputRange from 'react-input-range';
import './slider-widget.scss';
import Widget from '../widget';
import {SliderWidget} from './slider-widget';

interface Range {
  min: number;
  max: number;
}
const SliderComponent: React.SFC<WidgetProps<SliderWidget>> = (props) => {
  const addFilter = React.useContext(AddFilterContext);
  const filters = React.useContext(FilterContext);
  const {widget} = props;
  const {settings} = widget;
  const data = props.data[1];
  const fullRange = React.useMemo(() => {
    const vals = data.map((d) => d[settings.variable.name] as number);
    const nonNulls = vals.filter(
      (v) => v !== null && v !== undefined && !Number.isNaN(v),
    );
    return {
      min: Math.min(...nonNulls),
      max: Math.max(...nonNulls),
    };
  }, [settings.variable.name, data]);
  const value = React.useMemo<Range>(() => {
    const filter = filters.find(
      (f) => f.variable.name === settings.variable.name && f.type === 'BETWEEN',
    );
    if (!filter) {
      return fullRange;
    }
    return {
      min: filter.values[0] as number,
      max: filter.values[1] as number,
    };
  }, [settings.variable.name, fullRange, filters]);
  const handleChange = React.useCallback(
    (range: Range) => {
      addFilter({
        labelMap: {},
        variable: settings.variable,
        variableTitle: settings.title,
        values: [range.min, range.max],
        type: 'BETWEEN',
      });
    },
    [addFilter, settings.variable, settings.title],
  );

  const range = fullRange.max - fullRange.min;
  return (
    <Widget
      widget={widget}
      className="slider-widget"
      title={settings.title}
      readonly={props.readonly}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
    >
      <div className="slider-container">
        <InputRange
          disabled={!props.readonly}
          value={value}
          maxValue={fullRange.max}
          minValue={fullRange.min}
          onChange={handleChange as (val: Range | number) => void} // Cast needed due to bad typing on input-range library
          step={range > 300 ? Math.round(range / 30) : 1}
        />
      </div>
    </Widget>
  );
};

export default SliderComponent;
