import {Variable} from '../../data-ingest/xlsx-reader';
import {Widget, WidgetValue, BaseWidgetSettings, BaseWidget} from '../types';
import startCase from 'lodash/startCase';
import sortBy from 'lodash/sortBy';
import {palette} from '../../palette';

export interface PieWidgetSettings extends BaseWidgetSettings {
  values: WidgetValue[];
  variable: Variable;
}

export interface PieWidget extends BaseWidget<PieWidgetSettings> {
  type: 'pie';
}

export const canCreatePie: (variable: Variable) => boolean = (
  variable: Variable,
) => {
  return variable.uniqueValues.length <= 5;
};

export const createPieWidget = (
  variable: Variable,
  nextLayout: Widget['settings']['layout'],
): Widget => {
  const values = sortBy(variable.uniqueValues).map((val, i) => ({
    key: val,
    label: val.toString(),
    // Plus 10 to choose lighter tints
    color: palette[(i + 10) % palette.length],
  }));
  return {
    type: 'pie' as const,
    settings: {
      title: startCase(variable.name),
      variable,
      values,
      layout: {...nextLayout, w: 2, h: 12},
    },
  };
};
