import {Variable} from '../../data-ingest/xlsx-reader';
import {BaseWidget, BaseWidgetSettings, WidgetValue} from '../types';
import startCase from 'lodash/startCase';
import sortBy from 'lodash/sortBy';
import {palette} from '../../palette';

export interface ListWidgetSettings extends BaseWidgetSettings {
  values: WidgetValue[];
  variable: Variable;
}

export interface ListWidget extends BaseWidget<ListWidgetSettings> {
  type: 'list';
}

export const canCreateList: (variable: Variable) => boolean = (
  variable: Variable,
) => {
  return variable.uniqueValues.length > 0 && variable.uniqueValues.length < 100;
};

export const createListWidget = (
  variable: Variable,
  nextLayout: ListWidget['settings']['layout'],
): ListWidget => {
  const values = sortBy(variable.uniqueValues).map((val, i) => ({
    key: val,
    label: val.toString(),
    // - 10 avoids light shades
    color: palette[i % (palette.length - 10)],
  }));
  return {
    type: 'list' as const,
    settings: {
      title: startCase(variable.name),
      variable,
      values,
      layout: {
        ...nextLayout,
        w: 2,
        h: 12,
      },
    },
  };
};
