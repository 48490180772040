import * as React from 'react';
import PieChart, {EventHandler} from 'react-minimal-pie-chart';
import './pie-widget.scss';
import Legend from '../legend';
import {MergedData, ValueClickHandler, WidgetProps} from '../types';
import {AddFilterContext, NO_DATA_KEY} from '../../filters/filter-provider';
import Widget from '../widget';
import {PieWidget} from './pie-widget';

const PieComponent: React.SFC<WidgetProps<PieWidget>> = (props) => {
  const {widget} = props;
  const {settings} = widget;
  const addFilter = React.useContext(AddFilterContext);
  const handleClick = React.useCallback<ValueClickHandler>(
    (event: React.MouseEvent, data: MergedData[], dataIndex: number) => {
      addFilter({
        variable: settings.variable,
        variableTitle: settings.title,
        values: [data[dataIndex].key],
        labelMap: {[data[dataIndex].key]: data[dataIndex].label},
        type: 'MATCH',
      });
    },
    [addFilter, settings.variable, settings.title],
  );

  const pieChartData = React.useMemo<MergedData[]>(() => {
    const data = settings.values.map((v) => ({
      ...v,
      value:
        (props.filteredData[1].filter(
          (r) => r[settings.variable.name] === v.key,
        ).length /
          props.filteredData[1].length) *
        100,
      style: {cursor: 'pointer'},
    }));
    const missing = props.filteredData[1].filter(
      (r) => r[settings.variable.name] === undefined,
    );
    if (missing.length > 0) {
      data.push({
        value: (missing.length / props.filteredData[1].length) * 100,
        key: NO_DATA_KEY,
        label: 'No Data',
        color: '#333',
        style: {cursor: 'pointer'},
      });
    }

    return data;
  }, [settings.values, props.filteredData, settings.variable]);

  return (
    <Widget
      widget={widget}
      className="pie-widget"
      title={settings.title}
      onEdit={props.onEdit}
      readonly={props.readonly}
      onDelete={props.onDelete}
      allowSnapshot
    >
      <PieChart
        className="graphic-container"
        data={pieChartData}
        onClick={props.readonly ? (handleClick as EventHandler) : undefined}
      />
      <Legend data={pieChartData} onClick={handleClick} />
    </Widget>
  );
};

export default PieComponent;
