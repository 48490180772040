import * as React from 'react';
import {TextWidget} from './text-widget';
import {Formik, Form, Field} from 'formik';
import {ModalBody, ModalFooter, Label, FormGroup, Input} from 'reactstrap';

interface TextSettingFormValues {
  title: string;
}

interface Props {
  widget: TextWidget;
  onSubmit: (newSettings: TextSettingFormValues) => void;
  controls: React.ReactNode;
}

const TextSettingsForm: React.FC<Props> = (props) => {
  const initialValues: TextSettingFormValues = {
    title: props.widget.settings.title,
  };

  return (
    <Formik<TextSettingFormValues>
      initialValues={initialValues}
      onSubmit={(values) => props.onSubmit(values)}
    >
      <Form translate="">
        <ModalBody>
          <FormGroup>
            <Label htmlFor="text">Text</Label>
            <Field id="title" name="title" as={Input} />
          </FormGroup>
        </ModalBody>
        <ModalFooter>{props.controls}</ModalFooter>
      </Form>
    </Formik>
  );
};

export default TextSettingsForm;
