import * as React from 'react';
import {ValueClickHandler, MergedData} from './types';

interface LegendProps {
  data: MergedData[];
  onClick: ValueClickHandler;
}

const Legend: React.SFC<LegendProps> = (props) => {
  return (
    <div className="legend">
      {props.data.map((d) => (
        <div className="legend-color" key={d.key}>
          <svg viewBox="0 0 10 10" className="legend-color-swatch">
            <rect x="0" y="0" height="10" width="10" fill={d.color} />
          </svg>
          <span className="legend-color-label">
            {d.label}: {d.value.toFixed(0)}%
          </span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
