import * as React from 'react';
import {DataContext} from '../../data-fetcher/data-provider';
import VariableWidgets from './variable-widgets';
import {Widget} from '../../widgets/types';
import {Layout} from 'react-grid-layout';
import {createTextWidget} from '../../widgets/text/text-widget';
import {FiItalic, FiBarChart2} from 'react-icons/fi';
import WidgetButton from './widget-button';
import './widget-adder.scss';
import {createStackedBarsWidget} from '../../widgets/stacked-bars/stacked-bars-widget';

interface Props {
  addWidget: (widget: Widget) => void;
  nextLayout: Layout;
}

const WidgetAdder: React.FC<Props> = (props) => {
  const data = React.useContext(DataContext);
  const {addWidget, nextLayout} = props;

  const addTextWidget = React.useCallback(() => {
    addWidget(createTextWidget(nextLayout));
  }, [addWidget, nextLayout]);
  const addStackedBarsWidget = React.useCallback(() => {
    addWidget(createStackedBarsWidget(nextLayout));
  }, [addWidget, nextLayout]);

  if (data === null) {
    return <div>Error fetching data</div>;
  }
  const variables = data[0];
  return (
    <div className="widget-adder">
      <div className="widget-adder-header">General</div>
      <div className="widget-adder-section">
        <div className="widget-row">
          <div className="widget-name">Text</div>
          <div className="widget-widgets">
            <WidgetButton
              onClick={addTextWidget}
              icon={<FiItalic />}
              label="Text"
            />
          </div>
        </div>
        <div className="widget-row">
          <div className="widget-name">Stacked Bars</div>
          <div className="widget-widgets">
            <WidgetButton
              onClick={addStackedBarsWidget}
              icon={<FiBarChart2 />}
              label="Stacked Bars"
            />
          </div>
        </div>
      </div>
      <div className="widget-adder-header">Variables</div>
      <div className="widget-adder-section">
        {variables.map((v, i) => (
          <VariableWidgets
            key={i}
            variable={v}
            addWidget={props.addWidget}
            nextLayout={props.nextLayout}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetAdder;
