import * as React from 'react';
import {ListWidget} from './list-widget';
import {Formik, Form, Field} from 'formik';
import {ModalBody, ModalFooter, FormGroup, Label, Input} from 'reactstrap';
import {WidgetValue} from '../types';
import ValueFields from '../value-fields';

interface ListSettingFormValues {
  values: WidgetValue[];
  title: string;
}

interface Props {
  widget: ListWidget;
  onSubmit: (newSettings: ListSettingFormValues) => void;
  controls: React.ReactNode;
}

const ListSettingsForm: React.FC<Props> = (props) => {
  const initialValues: ListSettingFormValues = {
    values: props.widget.settings.values,
    title: props.widget.settings.title,
  };

  return (
    <Formik<ListSettingFormValues>
      initialValues={initialValues}
      onSubmit={(values) => props.onSubmit(values)}
    >
      {(formikBag) => {
        return (
          <Form translate="">
            <ModalBody>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Field name="title" as={Input} />
              </FormGroup>
              <ValueFields
                values={formikBag.values.values}
                setFieldValue={formikBag.setFieldValue}
              />
            </ModalBody>
            <ModalFooter>{props.controls}</ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ListSettingsForm;
