import * as React from 'react';
import {Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap';
import {firebaseApp} from './firebase';
import './login-page.scss';
import errorMessages from './error-mesasges';
import logo from '../logo.png';

const LoginPage: React.SFC<{}> = (props) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [forgotPasswordMode, setForgotPasswordMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = React.useCallback(
    (email: string, password: string) => {
      setLoading(true);
      setError('');
      setMessage('');
      let promise: Promise<any>;
      if (!forgotPasswordMode) {
        promise = firebaseApp
          .auth()
          .signInWithEmailAndPassword(email, password);
      } else {
        promise = firebaseApp
          .auth()
          .sendPasswordResetEmail(email)
          .then(() => {
            setError('');
            setLoading(false);
            setEmail('');
            setMessage('Please check your email for further instructions');
          });
      }
      promise.catch((err) => {
        console.error(err);
        const overrideMessage = errorMessages[err.code];
        setError(overrideMessage || err.message);
        setLoading(false);
      });
    },
    [forgotPasswordMode, setLoading],
  );

  const toggleForgotPasswordMode = React.useCallback(() => {
    setPassword('');
    setError('');
    setMessage('');
    setForgotPasswordMode(!forgotPasswordMode);
  }, [forgotPasswordMode, setPassword, setForgotPasswordMode]);

  return (
    <div className="login-page">
      <div className="d-flex flex-row justify-content-center mb-3">
        <img src={logo} className="logo" alt="CQ Insights Logo" />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(email, password);
        }}
        disabled={loading}
      >
        {error && <Alert color="danger">{error}</Alert>}
        {message && <Alert color="success">{message}</Alert>}
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input
            name="email"
            type="email"
            id="email"
            required
            disabled={loading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        {!forgotPasswordMode && (
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              name="password"
              type="password"
              disabled={loading}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
        )}
        <div className="controls justify-content-end d-flex">
          <Button
            disabled={loading}
            color="link"
            onClick={toggleForgotPasswordMode}
          >
            {forgotPasswordMode ? 'Log in' : 'Forgot password'}
          </Button>
          <Button disabled={loading} color="primary">
            {forgotPasswordMode ? 'Send email' : 'Log in'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LoginPage;
