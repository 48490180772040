import * as React from 'react';
import {
  FilterContext,
  RemoveFilterContext,
  FilteredDataContext,
  ResetFiltersContext,
} from './filter-provider';
import './filter-viewer.scss';
import {Button} from 'reactstrap';
import {FiXCircle, FiRefreshCw} from 'react-icons/fi';
import {DataContext} from '../data-fetcher/data-provider';

const FilterViewer: React.FC<{}> = () => {
  const data = React.useContext(DataContext) || [[], []];
  const filteredData = React.useContext(FilteredDataContext) || [[], []];
  const filters = React.useContext(FilterContext);
  const removeFilter = React.useContext(RemoveFilterContext);
  const resetFilters = React.useContext(ResetFiltersContext);
  return (
    <div className="filter-viewer">
      <div className="filter filter-summary">
        <div className="filter-variable">Filtered</div>
        <div className="filter-values">
          <div className="filter-value">{filteredData[1].length}</div>
          <div className="filter-conjuction">OUT OF</div>
          <div className="filter-value">{data[1].length}</div>
        </div>
        <div className="filter-variable">Records</div>
        <div className="filter-controls">
          <Button
            onClick={resetFilters}
            color="link"
            size="sm"
            disabled={filters.length === 0}
          >
            <FiRefreshCw />
          </Button>
        </div>
      </div>
      {filters.map((f, i) => (
        <div key={i} className="filter">
          {f.type === 'MATCH' ? (
            <>
              <div className="filter-variable">
                {f.variableTitle || f.variable.name}
              </div>
              <div className="filter-values">
                {f.values
                  .map<React.ReactNode>((v) => (
                    <div className="filter-value" key={`v-${v}`}>
                      {f.labelMap[v] || v}
                    </div>
                  ))
                  .reduce((prev, curr, i) => [
                    prev,
                    <div className="filter-conjuction" key={i}>
                      OR
                    </div>,
                    curr,
                  ])}
              </div>
            </>
          ) : f.type === 'BETWEEN' ? (
            <>
              <div className="filter-variable">{f.variable.name}</div>
              <div className="filter-values">
                <div className="filter-conjuction">BETWEEN</div>
                <div className="filter-value">{f.values[0]}</div>
                <div className="filter-conjuction">AND</div>
                <div className="filter-value">{f.values[1]}</div>
              </div>
            </>
          ) : (
            'Unknown filter type'
          )}
          <div className="filter-controls">
            <Button onClick={() => removeFilter(i)} color="link" size="sm">
              <FiXCircle />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterViewer;
