import * as React from 'react';
import {WidgetProps} from '../types';
import Widget from '../widget';
import {StackedBarsWidget, StackedBarsBar} from './stacked-bars-widget';
import StackedBar, {BarData} from '../stacked-bar';
import startCase from 'lodash/startCase';
import meanBy from 'lodash/meanBy';
import sumBy from 'lodash/sumBy';
import './stacked-bars-component.scss';
import {Row, Container, Col} from 'reactstrap';
import {blues, reds} from '../../palette';

const positiveColors = blues;
const negativeColors = reds;

const StackedBarsComponent: React.SFC<WidgetProps<StackedBarsWidget>> = (
  props,
) => {
  const {widget} = props;
  const {settings} = widget;

  const filteredData = props.filteredData[1];

  interface BarWithData extends StackedBarsBar {
    data: [BarData[], BarData[]];
  }

  const barsData: BarWithData[] = React.useMemo(
    () =>
      settings.bars.length === 0
        ? []
        : settings.bars.map((bar) => {
            const baseData = bar.variables.map((v) => ({
              mean: meanBy(filteredData, v.name),
              label: startCase(v.name),
              key: v.name,
            }));
            // Split data into positive and negative groups
            const positiveData = baseData.filter((d) => d.mean >= 0);
            const negativeData = baseData.filter((d) => d.mean < 0);
            return {
              ...bar,
              data: [positiveData, negativeData].map((ds, oneIfNeg) => {
                const meanSum = sumBy(ds, 'mean');
                return ds.map((d, i) => ({
                  key: d.key,
                  label: `Average ${d.label}: ${d.mean.toFixed(2)}`,
                  value: d.mean,
                  color:
                    oneIfNeg === 0
                      ? positiveColors[i % positiveColors.length]
                      : negativeColors[i % negativeColors.length],
                  length: Math.abs((d.mean / meanSum) * 100),
                })) as BarData[];
              }) as [BarData[], BarData[]],
            };
          }),
    [settings.bars, filteredData],
  );

  const maxPositiveLength = Math.abs(
    Math.max(
      ...barsData.map((bar) => bar.data[0] || []).map((b) => sumBy(b, 'value')),
    ),
  );
  const maxNegativeLength = Math.abs(
    Math.min(
      ...barsData.map((bar) => bar.data[1] || []).map((b) => sumBy(b, 'value')),
    ),
  );

  const zeroPosition =
    (maxPositiveLength / (maxPositiveLength + maxNegativeLength)) * 100;

  return (
    <Widget
      widget={widget}
      title={settings.title}
      className="stacked-bars-widget"
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      readonly={props.readonly}
      allowSnapshot
    >
      <Container>
        {barsData.map((bar, i) => (
          <Row className="align-items-center" key={i}>
            <Col xs={2} className="bar-title">
              {bar.title}
            </Col>
            <Col xs={10}>
              <div className="bar-row" key={i}>
                <div
                  className="bar-row-positive-container bar-side-container"
                  style={{width: `${zeroPosition}%`}}
                >
                  <div
                    className="bar-row-positve bar-side"
                    style={{
                      width: `${(sumBy(bar.data[0], 'value') /
                        maxPositiveLength) *
                        100}%`,
                      marginLeft: 'auto',
                    }}
                  >
                    <StackedBar stacks={bar.data[0]} />
                  </div>
                </div>
                <div
                  className="bar-row-negative-container bar-side-container"
                  style={{width: `${100 - zeroPosition}%`}}
                >
                  <div
                    className="bar-row-negative bar-side"
                    style={{
                      width: `${(-sumBy(bar.data[1], 'value') /
                        maxNegativeLength) *
                        100}%`,
                      marginRight: 'auto',
                    }}
                  >
                    <StackedBar stacks={bar.data[1]} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
    </Widget>
  );
};

export default StackedBarsComponent;
