import * as React from 'react';
import {ValueWidget, aggregators} from './value-widget';
import {Formik, Form, Field} from 'formik';
import {ModalBody, ModalFooter, FormGroup, Label, Input} from 'reactstrap';

interface ValueSettingFormValues {
  aggregator: ValueWidget['settings']['aggregator'];
  title: string;
}

interface Props {
  widget: ValueWidget;
  onSubmit: (newSettings: ValueSettingFormValues) => void;
  controls: React.ReactNode;
}

const ValueSettingsForm: React.FC<Props> = (props) => {
  const initialValues: ValueSettingFormValues = {
    aggregator: props.widget.settings.aggregator,
    title: props.widget.settings.title,
  };

  return (
    <Formik<ValueSettingFormValues>
      initialValues={initialValues}
      onSubmit={(values) => props.onSubmit(values)}
    >
      {(formikBag) => (
        <Form translate="">
          <ModalBody>
            <FormGroup>
              <Label htmlFor="title">Title</Label>
              <Field name="title" as={Input} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="aggregator">Aggregation type</Label>
              <Field id="aggregator" name="aggregator" as={Input} type="select">
                {aggregators.map((a) => (
                  <option key={a.type} value={a.type}>
                    {a.label}
                  </option>
                ))}
              </Field>
            </FormGroup>
          </ModalBody>
          <ModalFooter>{props.controls}</ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ValueSettingsForm;
