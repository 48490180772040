import * as React from 'react';
import {Modal, ModalBody, Button, ModalHeader, Alert} from 'reactstrap';
import {Formik, FormikHelpers, Field} from 'formik';
import {firebaseApp, firebaseAppForCreatingUsers} from './auth/firebase';
import FieldGroup from './field-group';
import CheckFieldGroup from './check-field-group';
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

interface UserValues {
  email: string;
  password: string;
  name: string;
  admin: boolean;
}

const blankUser: UserValues = {
  email: '',
  password: '',
  name: '',
  admin: false,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  name: Yup.string().required(),
  password: Yup.string().required(),
});

const NewUserModal: React.FC<Props> = (props) => {
  const {toggle} = props;
  const [formError, setFormError] = React.useState('');
  const handleSubmit = React.useCallback(
    (values: UserValues, formHelpers: FormikHelpers<any>) => {
      (async () => {
        setFormError('');
        try {
          const authUser = await firebaseAppForCreatingUsers
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password);
          await firebaseAppForCreatingUsers.auth().signOut();
          if (!authUser || !authUser.user) {
            throw new Error('Error creating auth user');
          }
          await firebaseApp
            .firestore()
            .collection('users')
            .doc(authUser.user.uid)
            .set({clientId: values.name, admin: values.admin});
          formHelpers.resetForm();
          toggle();
        } catch (err) {
          console.error(err);
          setFormError(err.message);
        } finally {
          formHelpers.setSubmitting(false);
        }
      })();
    },
    [toggle],
  );

  return (
    <Modal isOpen={!!props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Create User</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={blankUser}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(form) => (
            <form onSubmit={form.handleSubmit}>
              <Field component={FieldGroup} name="name" label="Name" />
              <Field component={FieldGroup} name="email" label="Email" />
              <Field
                component={FieldGroup}
                name="password"
                label="Password"
                type="password"
              />
              <Field component={CheckFieldGroup} name="admin" label="Admin?" />
              <Button
                color="primary"
                type="submit"
                disabled={form.isSubmitting}
              >
                Create User
              </Button>
              <Alert isOpen={!!formError} color="danger" className="mt-3">
                {formError}
              </Alert>
            </form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default NewUserModal;
