import * as React from 'react';
import {ProcessedSheet} from '../data-ingest/xlsx-reader';
import {Widget} from './types';
import TextComponent from './text/text-component';
import StackedBarsComponent from './stacked-bars/stacked-bars-component';
import ValueComponent from './value/value-component';
import SliderComponent from './slider/slider-component';
import ListComponent from './list/list-component';
import PieComponent from './pie/pie-component';

interface Props {
  data: ProcessedSheet;
  filteredData: ProcessedSheet;
  onEdit?: () => void;
  onDelete?: (widget: Widget) => void;
  readonly: boolean;
  widget: Widget;
}

const WidgetRenderer: React.FC<Props> = (props) => {
  const {widget, onDelete} = props;
  const handleDelete = React.useCallback(
    () => (onDelete ? onDelete(widget) : () => void 0),
    [onDelete, widget],
  );
  return widget.type === 'pie' ? (
    <PieComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : widget.type === 'list' ? (
    <ListComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : widget.type === 'slider' ? (
    <SliderComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : widget.type === 'value' ? (
    <ValueComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : widget.type === 'stackedbars' ? (
    <StackedBarsComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : widget.type === 'text' ? (
    <TextComponent {...props} widget={widget} onDelete={handleDelete} />
  ) : (
    <div>Unknown widget type</div>
  );
};

export default WidgetRenderer;
