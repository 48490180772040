import zip from 'lodash/zip';
import flatten from 'lodash/flatten';
import tinycolor from 'tinycolor2';

// From https://github.com/edelstone/tints-and-shades/blob/master/scripts/tints-and-shades.js
const rgbTint = (rgb: {r: number; g: number; b: number}, i: number) => {
  return {
    r: rgb.r + (255 - rgb.r) * i * 0.1,
    g: rgb.g + (255 - rgb.g) * i * 0.1,
    b: rgb.b + (255 - rgb.b) * i * 0.1,
  };
};

const generateTints = (hex: string) => {
  const tints = [];
  const base = tinycolor(hex).toRgb();
  for (let i = 0; i < 10; i++) {
    tints.push(rgbTint(base, i));
  }
  return tints.map((rgb) => tinycolor(rgb).toHexString());
};

export const blues = generateTints('#6184d8');
export const reds = generateTints('#bb0a21');
export const greens = generateTints('#6bd425');
export const oranges = generateTints('#f77f00');
export const navies = generateTints('#003049');

export const palette = flatten(
  zip(blues, reds, greens, oranges, navies),
) as string[];
