import * as React from 'react';
import {firebaseApp} from './firebase';

export interface DashboardRecord {
  name?: string;
  userIds?: string[];
  serializedDashboard?: string;
  processedSheetId?: string;
}

const useDashboard = (
  dashboardId: string,
): [DashboardRecord | null, boolean, string] => {
  const [dashboard, setDashboard] = React.useState<DashboardRecord | null>(
    null,
  );
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  React.useEffect(() => {
    firebaseApp
      .firestore()
      .collection('dashboards')
      .doc(dashboardId)
      .get()
      .then((res) => {
        const data = res.data();
        if (!data) {
          throw new Error(`No dashboard with id ${dashboardId} found.`);
        }
        setDashboard(data as DashboardRecord);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching dashboard', err);
        setError(err.message);
      });
  }, [dashboardId]);
  return [dashboard, loading, error];
};

export default useDashboard;
