import * as React from 'react';
import {Variable} from '../../data-ingest/xlsx-reader';
import startCase from 'lodash/startCase';
import {createPieWidget, canCreatePie} from '../../widgets/pie/pie-widget';
import {canCreateList, createListWidget} from '../../widgets/list/list-widget';
import {
  canCreateSlider,
  createSliderWidget,
} from '../../widgets/slider/slider-widget';
import {
  createValueWidget,
  canCreateValue,
} from '../../widgets/value/value-widget';
import {FiPieChart, FiList, FiSliders, FiItalic} from 'react-icons/fi';
import {Widget} from '../../widgets/types';
import {Layout} from 'react-grid-layout';
import WidgetButton from './widget-button';

interface Props {
  variable: Variable;
  addWidget: (widget: Widget) => void;
  nextLayout: Layout;
}
const VariableWidgets: React.FC<Props> = (props) => {
  const {variable, addWidget, nextLayout} = props;
  const addPieWidget = React.useCallback(() => {
    const widget = createPieWidget(variable, nextLayout);
    addWidget(widget);
  }, [variable, addWidget, nextLayout]);
  const addListWidget = React.useCallback(() => {
    const widget = createListWidget(variable, nextLayout);
    addWidget(widget);
  }, [variable, addWidget, nextLayout]);
  const addSliderWidget = React.useCallback(() => {
    const widget = createSliderWidget(variable, nextLayout);
    addWidget(widget);
  }, [variable, addWidget, nextLayout]);
  const addValueWidget = React.useCallback(() => {
    const widget = createValueWidget(variable, nextLayout);
    addWidget(widget);
  }, [variable, addWidget, nextLayout]);

  return (
    <div className="widget-row">
      <div className="widget-name">{startCase(props.variable.name)}</div>
      <div className="widget-widgets">
        {canCreatePie(props.variable) ? (
          <WidgetButton
            key="pie"
            onClick={addPieWidget}
            icon={<FiPieChart />}
            label="Pie"
          />
        ) : (
          <div className="widget-placeholder" />
        )}
        {canCreateList(props.variable) ? (
          <WidgetButton
            key="list"
            onClick={addListWidget}
            icon={<FiList />}
            label="List"
          />
        ) : (
          <div className="widget-placeholder" />
        )}
        {canCreateSlider(props.variable) ? (
          <WidgetButton
            key="slider"
            onClick={addSliderWidget}
            icon={<FiSliders />}
            label="Slider"
          />
        ) : (
          <div className="widget-placeholder" />
        )}
        {canCreateValue(props.variable) ? (
          <WidgetButton
            key="value"
            onClick={addValueWidget}
            icon={<FiItalic />}
            label="Value"
          />
        ) : (
          <div className="widget-placeholder" />
        )}
      </div>
    </div>
  );
};

export default VariableWidgets;
