import * as React from 'react';
import {useExcelData} from '../auth/use-excel-data';
import {ProcessedSheet} from '../data-ingest/xlsx-reader';
import FilterProvider from '../filters/filter-provider';
import LoadingPage from '../loading/loading-page';

export const DataContext = React.createContext<ProcessedSheet | null>(null);

const DataProvider: React.FC<{processedSheetId: string}> = (props) => {
  const [data, isLoading, hasError] = useExcelData(props.processedSheetId);
  if (isLoading) {
    return <LoadingPage />;
  }

  if (hasError || !data) {
    return <span>There was an error loading data</span>;
  }
  return (
    <DataContext.Provider value={data}>
      <FilterProvider>{props.children}</FilterProvider>
    </DataContext.Provider>
  );
};

export default DataProvider;
