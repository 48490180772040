import * as React from 'react';
import {useRouteMatch} from 'react-router-dom';
import DashboardProvider from '../data-fetcher/dashboard-provider';
import DashboardViewer from '../dashboard-builder/dashboard-viewer';

interface Props {}

const DashboardPage: React.FC<Props> = (props) => {
  const route = useRouteMatch<{id: string}>();
  return (
    <div className="dashboard-page">
      <DashboardProvider dashboardId={route.params.id}>
        <DashboardViewer readonly />
      </DashboardProvider>
    </div>
  );
};

export default DashboardPage;
