import * as React from 'react';
import {FiEdit} from 'react-icons/fi';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {Button, Container, Table} from 'reactstrap';
import UploadModal from '../admin/upload-modal';
import {firebaseApp} from '../auth/firebase';
import {DbUser} from '../auth/use-current-user';
import {DashboardRecord} from '../auth/use-dashboard';
import NewUserModal from '../new-user-modal';
import EditUserModal from '../edit-user-modal';

interface Props {}

type IdRecord<T> = T & {id: string};

interface SheetRecord {
  fileName: string;
  lastModified: string;
  serializedProcessedData: string;
  serializedVersion: string;
  storagePath: string;
  uploadedAt: string;
}

const AdminPage: React.FC<Props> = (props) => {
  const [dashboards, setDashboards] = React.useState<
    IdRecord<DashboardRecord>[]
  >([]);
  const [sheets, setSheets] = React.useState<IdRecord<SheetRecord>[]>([]);
  const [users, setUsers] = React.useState<IdRecord<DbUser>[]>([]);
  const [showCreateUserModal, setShowCreateUserModal] = React.useState(false);

  React.useEffect(() => {
    return firebaseApp
      .firestore()
      .collection('dashboards')
      .onSnapshot((data) => {
        setDashboards(
          data.docs.map((d) => ({...(d.data() as DashboardRecord), id: d.id})),
        );
      });
  }, [setDashboards]);

  React.useEffect(() => {
    return firebaseApp
      .firestore()
      .collection('users')
      .onSnapshot((data) => {
        setUsers(data.docs.map((d) => ({...(d.data() as DbUser), id: d.id})));
      });
  }, [setUsers]);

  React.useEffect(() => {
    return firebaseApp
      .firestore()
      .collection('processedSheets')
      .onSnapshot((data) => {
        setSheets(
          data.docs.map((d) => ({...(d.data() as SheetRecord), id: d.id})),
        );
      });
  }, [setSheets]);

  const removeUserFromDashboard = React.useCallback(
    async (dashboardId: string, userId: string) => {
      const dashboard = dashboards.find((db) => db.id === dashboardId)!;
      return firebaseApp
        .firestore()
        .collection('dashboards')
        .doc(dashboard.id)
        .update({
          userIds: (dashboard.userIds || []).filter((id) => id !== userId),
        });
    },
    [dashboards],
  );

  const addUserToDashboard = React.useCallback(
    async (dashboardId: string, userId: string) => {
      const dashboard = dashboards.find((db) => db.id === dashboardId)!;
      return firebaseApp
        .firestore()
        .collection('dashboards')
        .doc(dashboard.id)
        .update({
          userIds: [...(dashboard.userIds || []), userId],
        });
    },
    [dashboards],
  );

  const createNewDashboard = React.useCallback(async () => {
    return firebaseApp
      .firestore()
      .collection('dashboards')
      .add({
        name: 'Unnamed Dashboard',
        serializedDashboard: JSON.stringify({widgets: []}, null, 2),
      });
  }, []);

  const [
    dashboardToChangeData,
    setDashboardToChangeData,
  ] = React.useState<IdRecord<DashboardRecord> | null>(null);

  const [userIdToEdit, setUserIdToEdit] = React.useState<string | null>(null);
  const [userToEdit, setUserToEdit] = React.useState<DbUser | null>(null);
  const handleEditUser = React.useCallback(
    (id: string) => {
      const user = users.find((u) => id === u.id);
      if (user) {
        setUserIdToEdit(id);
        setUserToEdit(user);
      }
    },
    [users],
  );

  const handleDeleteDashboard = (dashboard: IdRecord<DashboardRecord>) => {
    if (
      !window.confirm(
        `Are you absolutely sure you want to delete ${dashboard.name}?`,
      )
    ) {
      return;
    }
    return firebaseApp
      .firestore()
      .collection('dashboards')
      .doc(dashboard.id)
      .delete();
  };

  return (
    <Container>
      <h2>Dashboards</h2>
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Data file</th>
            <th>Users</th>
          </tr>
        </thead>
        <tbody>
          {dashboards.map((dr) => (
            <tr key={dr.id}>
              <td>{dr.name || 'Unnamed Dashboard'}</td>
              <td>
                {dr.processedSheetId
                  ? sheets.find((s) => s.id === dr.processedSheetId)
                    ? sheets.find((s) => s.id === dr.processedSheetId)!.fileName
                    : 'Unknown sheet'
                  : 'None'}
                <Button
                  color="link"
                  onClick={() => setDashboardToChangeData(dr)}
                  style={{verticalAlign: 'initial'}}
                >
                  <FiEdit />
                </Button>
              </td>
              <td>
                <ul style={{listStyle: 'none', paddingLeft: 0}}>
                  {!dr.userIds || dr.userIds.length === 0
                    ? 'None'
                    : dr.userIds.map((id) => (
                        <li key={id}>
                          {users.find((u) => u.id === id)
                            ? users.find((u) => u.id === id)!.clientId
                            : 'Unknown user'}
                          <Button
                            color="link"
                            onClick={() => removeUserFromDashboard(dr.id, id)}
                            style={{verticalAlign: 'initial'}}
                          >
                            &times;
                          </Button>
                        </li>
                      ))}
                </ul>
                <b>Add another user:</b>
                <Select
                  options={users
                    .filter((u) => !u.admin)
                    .filter((u) => (dr.userIds || []).indexOf(u.id) === -1)
                    .map((u) => ({
                      value: u.id,
                      label: u.clientId || 'Unnamed User',
                    }))}
                  value={null}
                  onChange={(val, action) => {
                    // @ts-ignore
                    if (val && val.value) {
                      // @ts-ignore
                      addUserToDashboard(dr.id, val.value);
                    }
                  }}
                />
              </td>
              <td>
                <div className="text-nowrap">
                  <Link
                    className="btn btn-primary mr-1"
                    to={`/dashboards/${dr.id}/edit`}
                  >
                    Edit
                  </Link>
                  <Button
                    onClick={() => handleDeleteDashboard(dr)}
                    color="danger"
                  >
                    Delete
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button onClick={createNewDashboard} color="primary">
        Create new dashboard
      </Button>
      <UploadModal
        dashboardRecord={dashboardToChangeData}
        onClose={() => setDashboardToChangeData(null)}
      />
      <h2 className="mt-4">Users</h2>
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Admin?</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.id}>
              <td>{u.clientId}</td>
              <td>{u.admin ? 'Yes' : 'No'}</td>
              <td>
                <Button color="primary" onClick={() => handleEditUser(u.id)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button onClick={() => setShowCreateUserModal(true)} color="primary">
        Create new user
      </Button>
      <NewUserModal
        toggle={() => setShowCreateUserModal(false)}
        isOpen={showCreateUserModal}
      />
      <EditUserModal
        isOpen={!!userIdToEdit && !!userToEdit}
        initialValues={
          userToEdit
            ? {
                name: userToEdit.clientId || '',
                admin: userToEdit.admin || false,
              }
            : null
        }
        id={userIdToEdit}
        toggle={() => {
          setUserIdToEdit(null);
          setUserToEdit(null);
        }}
      />
    </Container>
  );
};

export default AdminPage;
