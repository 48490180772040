import * as React from 'react';
import './widget.scss';
import {Widget as WidgetType} from './types';
import {FiEdit, FiTrash, FiCamera} from 'react-icons/fi';
import componentSnapshot from '../componentSnapshot';

interface Props {
  className?: string;
  title?: string;
  widget: WidgetType;
  onEdit?: (widget: WidgetType) => void;
  onDelete?: (widget: WidgetType) => void;
  readonly: boolean;
  allowSnapshot?: boolean;
}

const Widget: React.SFC<Props> = (props) => {
  const {onEdit, onDelete, widget} = props;
  const handleEdit = React.useCallback(
    () => (onEdit ? onEdit(widget) : () => void 0),
    [onEdit, widget],
  );
  const handleDelete = React.useCallback(
    () => (onDelete ? onDelete(widget) : () => void 0),
    [onDelete, widget],
  );
  const handleSnapshot = () =>
    componentSnapshot(ref, `${props.title || 'Vality Snapshot'}.png`);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={`widget ${props.className ? props.className : ''}`}
      ref={ref}
    >
      {props.readonly ? (
        props.allowSnapshot && (
          <div className="controls only-on-hover remove-from-snapshot">
            <FiCamera onClick={handleSnapshot} />
          </div>
        )
      ) : (
        <div className="controls">
          {props.onEdit && <FiEdit onClick={handleEdit} />}
          {props.onDelete && <FiTrash onClick={handleDelete} />}
        </div>
      )}
      {props.title && <div className="title">{props.title}</div>}
      {props.children}
    </div>
  );
};

export default Widget;
