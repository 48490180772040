import * as React from 'react';
import differenceBy from 'lodash/differenceBy';
import {Widget} from '../../widgets/types';
import {Layout} from 'react-grid-layout';
import {Dashboard} from '../types';

const updateLayout = (layouts: Layout[], widgets: Widget[]) => {
  const newWidgets: Widget[] = [];
  for (const l of layouts) {
    const oldWidget = widgets.find((w) => w.settings.layout.i === l.i);
    if (!oldWidget) {
      console.error(
        `Error updating layout. No widget with key ${l.i} found in dashboard widgets ${widgets}. Layout may reset when adding/removing widgets.`,
      );
    } else {
      newWidgets.push({
        ...oldWidget,
        settings: {...oldWidget.settings, layout: l},
      } as typeof oldWidget);
    }
  }
  return [
    ...differenceBy(widgets, newWidgets, (w) => w.settings.layout.i),
    ...newWidgets,
  ];
};

const useDashboardControls = (
  dashboard: Dashboard,
  setDashboard: (dashboard: Dashboard) => void,
) => {
  const addWidget = React.useCallback(
    (widget: Widget) => {
      setDashboard({
        ...dashboard,
        widgets: dashboard.widgets.concat(widget),
      });
    },
    [dashboard, setDashboard],
  );

  const handleLayoutChange = React.useCallback(
    (layouts: Layout[]) => {
      setDashboard({
        ...dashboard,
        widgets: updateLayout(layouts, dashboard.widgets),
      });
    },
    [dashboard, setDashboard],
  );

  const updateWidget = React.useCallback(
    (newWidget: Widget) => {
      const ind = dashboard.widgets.findIndex(
        (w) => w.settings.layout.i === newWidget.settings.layout.i,
      );
      setDashboard({
        ...dashboard,
        widgets: [
          ...dashboard.widgets.slice(0, ind),
          newWidget,
          ...dashboard.widgets.slice(ind + 1),
        ],
      });
    },
    [dashboard, setDashboard],
  );

  const deleteWidget = React.useCallback(
    (widgetToDelete: Widget) => {
      const ind = dashboard.widgets.findIndex(
        (w) => w.settings.layout.i === widgetToDelete.settings.layout.i,
      );
      setDashboard({
        ...dashboard,
        widgets: [
          ...dashboard.widgets.slice(0, ind),
          ...dashboard.widgets.slice(ind + 1),
        ],
      });
    },
    [dashboard, setDashboard],
  );

  return [addWidget, updateWidget, deleteWidget, handleLayoutChange] as const;
};

export default useDashboardControls;
