import * as React from 'react';
import {WidgetProps} from '../types';
import './value-widget.scss';
import Widget from '../widget';
import {ValueWidget, aggregators} from './value-widget';
import isNumber from 'lodash/isNumber';

const ValueComponent: React.SFC<WidgetProps<ValueWidget>> = (props) => {
  const {filteredData, widget} = props;
  const {settings} = widget;
  const agg = React.useMemo(() => {
    const a = aggregators.find((a) => a.type === settings.aggregator);
    if (!a) {
      console.error(
        `unknown aggregator type ${settings.aggregator} falling back to mean`,
      );
      return aggregators[0];
    }
    return a;
  }, [settings.aggregator]);

  const value = React.useMemo(() => {
    const data = filteredData[1]
      .map((d) => d[settings.variable.name])
      .filter((x) => isNumber(x)) as number[];
    return agg.fn(data);
  }, [filteredData, settings.variable.name, agg]);

  return (
    <Widget
      widget={widget}
      className="value-widget"
      title={props.widget.settings.title}
      onEdit={props.onEdit}
      onDelete={props.onDelete}
      readonly={props.readonly}
      allowSnapshot
    >
      {agg.label}: {value.toFixed(2)}
    </Widget>
  );
};

export default ValueComponent;
