import * as React from 'react';
import {Widget} from '../widgets/types';
import FilterViewer from '../filters/filter-viewer';
import ReactGridLayout, {Layout} from 'react-grid-layout';
import {DataContext} from '../data-fetcher/data-provider';
import {FilteredDataContext} from '../filters/filter-provider';
import WidgetRenderer from '../widgets/widget-renderer';
import {Dashboard} from './types';
import {
  DashboardContext,
  DashboardRecordContext,
} from '../data-fetcher/dashboard-provider';
import componentSnapshot from '../componentSnapshot';
import {FiCamera} from 'react-icons/fi';
import {Button} from 'reactstrap';

export interface Props {
  dashboard?: Dashboard;
  readonly?: boolean;
  onEdit?: () => void;
  onDelete?: (widget: Widget) => void;
  onLayoutChange?: (layouts: Layout[]) => void;
}

const DashboardViewer: React.FC<Props> = (props) => {
  const {
    dashboard: propsDashboard,
    readonly = true,
    onEdit,
    onDelete,
    onLayoutChange,
  } = props;
  const contextDashboard = React.useContext(DashboardContext);
  const dashboard = propsDashboard || contextDashboard;
  if (!dashboard) {
    throw new Error('Dashboard must be provided through props or context');
  }
  const data = React.useContext(DataContext)!;
  const filteredData = React.useContext(FilteredDataContext)!;
  const layout = React.useMemo<Layout[]>(
    () => dashboard.widgets.map((w) => w.settings.layout),
    [dashboard.widgets],
  );
  const dashboardRecord = React.useContext(DashboardRecordContext);
  const downloadScreenshot = async () => {
    componentSnapshot(gridRef, `${dashboardRecord?.name || 'Dashboard'}.png`);
  };
  const gridRef = React.createRef<HTMLDivElement>();
  return (
    <div
      className="dashboard-layout"
      style={{width: '1200px', minHeight: '500px', backgroundColor: 'white'}}
    >
      {dashboardRecord && dashboardRecord.name && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <h2>{dashboardRecord.name}</h2>
          <Button onClick={downloadScreenshot} color="link">
            <FiCamera />
          </Button>
        </div>
      )}

      <FilterViewer />
      <div ref={gridRef}>
        <ReactGridLayout
          className={props.readonly ? 'viewer' : 'editor'}
          layout={layout}
          width={1200}
          rowHeight={20}
          cols={8}
          onLayoutChange={onLayoutChange}
          compactType={null}
          preventCollision={true}
          isDraggable={!readonly}
          isResizable={!readonly}
        >
          {dashboard.widgets.map((w) => (
            <div key={w.settings.layout.i}>
              <WidgetRenderer
                key={w.settings.layout.i}
                data={data}
                filteredData={filteredData}
                readonly={readonly}
                onEdit={onEdit}
                onDelete={onDelete}
                widget={w}
              />
            </div>
          ))}
        </ReactGridLayout>
      </div>
    </div>
  );
};

export default DashboardViewer;
