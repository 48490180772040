import * as React from 'react';
import {
  StackedBarsWidget,
  canUseVariableForStackedBars,
  StackedBarsBar,
} from './stacked-bars-widget';
import {Formik, Form, Field, FieldArray, ArrayHelpers} from 'formik';
import {
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';
import {Variable} from '../../data-ingest/xlsx-reader';
import startCase from 'lodash/startCase';
import BarVariablesList from './bar-variables-list';
import './stacked-bars-settings-form.scss';
import {FiTrash, FiPlus} from 'react-icons/fi';

interface StackedBarsSettingFormValues {
  bars: StackedBarsBar[];
  title: string;
}

interface Props {
  widget: StackedBarsWidget;
  variables: Variable[];
  onSubmit: (newSettings: StackedBarsSettingFormValues) => void;
  controls: React.ReactNode;
}

const StackedBarsSettingsForm: React.FC<Props> = (props) => {
  const initialValues: StackedBarsSettingFormValues = {
    title: props.widget.settings.title,
    bars: props.widget.settings.bars,
  };

  const {variables} = props;

  return (
    <Formik<StackedBarsSettingFormValues>
      initialValues={initialValues}
      onSubmit={(values) => props.onSubmit(values)}
    >
      {(formikBag) => {
        return (
          <Form translate="">
            <ModalBody>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Field name="title" as={Input} />
              </FormGroup>
              <FieldArray name="bars">
                {(arrayHelpers: ArrayHelpers) => (
                  <div className="bars">
                    {formikBag.values.bars.map((bar, i) => (
                      <div className="bar" key={i}>
                        <FormGroup inline>
                          <Label htmlFor={`bars.${i}.title`}>Bar {i + 1}</Label>
                          <Field
                            name={`bars.${i}.title`}
                            id={`bars.${i}.title`}
                            as={Input}
                            placeholder="Bar Title"
                          />
                        </FormGroup>

                        <FieldArray
                          key={`bars.${i}.variables`}
                          name={`bars.${i}.variables`}
                        >
                          {(barHelpers: ArrayHelpers) => (
                            <div className="bar-variables">
                              <BarVariablesList
                                barVariables={bar.variables}
                                arrayHelpers={barHelpers}
                              />
                              <div className="controls">
                                <UncontrolledDropdown>
                                  <DropdownToggle color="link">
                                    <FiPlus />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {variables
                                      .filter(canUseVariableForStackedBars)
                                      .filter(
                                        (v) =>
                                          !bar.variables.find(
                                            (bv) => bv.name === v.name,
                                          ),
                                      )
                                      .map((v, i) => (
                                        <DropdownItem
                                          key={i}
                                          value={v.name}
                                          onClick={() => barHelpers.push(v)}
                                        >
                                          {startCase(v.name)}
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                                <Button
                                  onClick={() => arrayHelpers.remove(i)}
                                  color="link"
                                >
                                  <FiTrash />
                                </Button>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    ))}
                    <Button
                      onClick={() =>
                        arrayHelpers.push({
                          title: '',
                          variables: [],
                        } as StackedBarsBar)
                      }
                      className="mt-3"
                    >
                      Add new bar
                    </Button>
                  </div>
                )}
              </FieldArray>
            </ModalBody>
            <ModalFooter>{props.controls}</ModalFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StackedBarsSettingsForm;
