import * as xlsx from 'xlsx';
import fetch from 'isomorphic-fetch';

export interface Variable {
  name: string;
  uniqueValues: Array<ExcelValue>;
}

type ExcelValue = string | number;

export type ProcessedSheet = [Variable[], Array<{[key: string]: ExcelValue}>];

const processSheet = (
  sheet: Array<{[key: string]: ExcelValue}>,
): ProcessedSheet => {
  const variables: Variable[] = [];

  for (const row of sheet) {
    for (const rowVar of Object.keys(row)) {
      const variable = variables.find((v) => v.name === rowVar);
      if (variable) {
        // Add value to unique values list if not already in it.
        if (
          variable.uniqueValues.find((v) => v === row[rowVar]) === undefined
        ) {
          variable.uniqueValues.push(row[rowVar]);
        }
      } else {
        // Create new variable with value as only unique value.
        variables.push({
          name: rowVar,
          uniqueValues: [row[rowVar]],
        });
      }
    }
  }
  return [variables, sheet];
};

export const readFile = (path: string): ProcessedSheet => {
  const workbook = xlsx.readFile(path);
  const sheet: Array<{[key: string]: ExcelValue}> = xlsx.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]],
  );
  return processSheet(sheet);
};

export const readBuffer = (buffer: Buffer | Uint8Array): ProcessedSheet => {
  const workbook = xlsx.read(buffer, {
    type: Buffer.isBuffer(buffer) ? 'buffer' : 'array',
  });
  const sheet: Array<{[key: string]: ExcelValue}> = xlsx.utils.sheet_to_json(
    workbook.Sheets[workbook.SheetNames[0]],
  );
  return processSheet(sheet);
};

export const readUri = (uri: string): Promise<ProcessedSheet> => {
  return new Promise<ProcessedSheet>(async (resolve) => {
    fetch(uri).then((resp) => {
      if (resp.status >= 400) {
        throw new Error(
          `Error while fetching ${uri}: ${resp.statusText} ${resp.body}`,
        );
      }
      const workbook = xlsx.read(resp.body, {type: 'array'});

      const sheet: Array<{
        [key: string]: ExcelValue;
      }> = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      return resolve(processSheet(sheet));
    });
  });
};
