import * as React from 'react';
import {Layout} from 'react-grid-layout';
import './dashboard-builder.scss';
import WidgetAdder from './widget-adder/widget-adder';
import {DataContext} from '../data-fetcher/data-provider';
import {Widget} from '../widgets/types';
import WidgetSettingsModal from './widget-settings-modal';
import useDashboardControls from './hooks/use-dashboard-controls';
import DashboardViewer from './dashboard-viewer';
import {Dashboard} from './types';
import {Button, Input, FormGroup, Label} from 'reactstrap';
import stringify from 'json-stable-stringify';
import {Prompt} from 'react-router';
import {
  DashboardContext,
  DashboardRecordContext,
} from '../data-fetcher/dashboard-provider';

const nextWidgetPosition = (widgets: Widget[]): Layout => {
  const lastY = widgets.reduce<Widget | null>((a, b) => {
    if (a === null) {
      return b;
    }
    if (a.settings.layout.x === 0 && b.settings.layout.x === 0) {
      // If both in first column, return one with largest Y
      return a.settings.layout.y > b.settings.layout.y ? a : b;
    }
    if (b.settings.layout.x === 0) {
      // If only b in first column, return b
      return b;
    }
    // Otherwise, return a
    return a;
  }, null);

  const nextI =
    widgets.length > 0
      ? Math.max(
          ...widgets.map(
            (w) => parseInt(w.settings.layout.i!.split('-')[1]),
            10,
          ),
        ) + 1
      : 0;
  return {
    x: 0,
    // Get next Y position in first column
    y:
      lastY && lastY.settings.layout.x === 0
        ? lastY.settings.layout.y + lastY.settings.layout.h
        : 0,
    i: `l-${nextI}`,
    h: 4,
    w: 1,
  };
};

interface Props {
  onSave: (dashboard: Dashboard, name: string) => Promise<void>;
}

const DashboardBuilder: React.SFC<Props> = (props) => {
  const {onSave} = props;
  const initialDashboard = React.useContext(DashboardContext);
  const initialDashboardRecord = React.useContext(DashboardRecordContext);
  const initialName = initialDashboardRecord ? initialDashboardRecord.name : '';

  const [dashboard, setDashboard] = React.useState<Dashboard>(
    initialDashboard || {
      widgets: [],
    },
  );

  const dashboardHasChanged = React.useMemo(
    () =>
      stringify(initialDashboard ? initialDashboard.widgets : undefined) !==
      stringify(dashboard.widgets),
    [initialDashboard, dashboard],
  );

  React.useEffect(() => {
    if (dashboardHasChanged) {
      window.onbeforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      };
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [dashboardHasChanged]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [name, setName] = React.useState(initialName || '');

  const handleSave = React.useCallback(async () => {
    setIsSaving(true);
    await onSave(dashboard, name);
    setIsSaving(false);
  }, [onSave, setIsSaving, dashboard, name]);

  const [
    addWidget,
    updateWidget,
    deleteWidget,
    handleLayoutChange,
  ] = useDashboardControls(dashboard, setDashboard);

  const [readonly, setReadonly] = React.useState(false);

  const [widgetEditModalIsOpen, setWidgetEditModalIsOpen] = React.useState(
    false,
  );
  const [widgetToEdit, setWidgetToEdit] = React.useState<Widget | null>(null);
  const toggleWidgetSettingsModal = React.useCallback(
    (widget?: Widget) => {
      if (widgetEditModalIsOpen) {
        return setWidgetEditModalIsOpen(false);
      }
      if (widget === undefined) {
        console.error(
          'Called toggleWidgetSettingsModal when modal is closed, but did not provide ind',
        );
        return setWidgetEditModalIsOpen(false);
      }
      setWidgetEditModalIsOpen(true);
      setWidgetToEdit(widget);
    },
    [setWidgetToEdit, setWidgetEditModalIsOpen, widgetEditModalIsOpen],
  );

  const nextLayout = React.useMemo(
    () => nextWidgetPosition(dashboard.widgets),
    [dashboard.widgets],
  );

  const data = React.useContext(DataContext)!;

  return (
    <div className="dashboard-builder">
      <Prompt
        when={dashboardHasChanged}
        message="The changes you've made have not been saved. Are you sure you want to leave?"
      />
      <div className="controls">
        <div className="controls-buttons d-flex justify-content-between mb-3">
          <Button
            onClick={() => setReadonly(!readonly)}
            color="secondary"
            outline
          >
            {readonly ? 'Edit' : 'Preview'}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            disabled={
              isSaving ||
              (dashboard === initialDashboard && name === initialName)
            }
          >
            Save
          </Button>
        </div>
        <FormGroup>
          <Label htmlFor="name">Dashboard Name</Label>
          <Input
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <WidgetAdder addWidget={addWidget} nextLayout={nextLayout} />
      </div>
      <DashboardViewer
        dashboard={dashboard}
        readonly={readonly}
        onLayoutChange={handleLayoutChange}
        onEdit={toggleWidgetSettingsModal}
        onDelete={deleteWidget}
      />
      <WidgetSettingsModal
        widget={widgetToEdit}
        isOpen={widgetEditModalIsOpen}
        toggle={toggleWidgetSettingsModal}
        updateWidget={updateWidget}
        variables={data[0]}
      />
    </div>
  );
};

export default DashboardBuilder;
