import * as React from 'react';
import {ProcessedSheet} from '../data-ingest/xlsx-reader';
import {firebaseApp} from './firebase';
import {DataRecord} from '../admin/upload-modal';

// Increment this to invalidate cached data globally
export const VERSION = '1';

export const useExcelData: (
  id: string,
) => [ProcessedSheet | null, boolean, boolean] = (id) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [data, setData] = React.useState<null | ProcessedSheet>(null);
  React.useEffect(() => {
    setIsLoading(true);
    firebaseApp
      .firestore()
      .doc(`processedSheets/${id}`)
      .get()
      .then((ss) => {
        const record = ss.data() as DataRecord;
        if (!record || !record.serializedProcessedData) {
          setData([[], []]);
        } else {
          setData(JSON.parse(record.serializedProcessedData));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(`Error fetching data ${err.message}`);
        console.error(err);
        setHasError(true);
      });
  }, [id]);
  return [data, isLoading, hasError] as [
    ProcessedSheet | null,
    boolean,
    boolean,
  ];
};
