import * as React from 'react';
import {Variable, ProcessedSheet} from '../data-ingest/xlsx-reader';
import {DataContext} from '../data-fetcher/data-provider';
import differenceBy from 'lodash/differenceBy';
import intersection from 'lodash/intersection';

export const NO_DATA_KEY = 'NODATA';

interface ValueLabelMap {
  [value: string]: string;
}

interface Filter {
  variable: Variable;
  variableTitle?: string;
  values: Array<number | string>;
  labelMap: ValueLabelMap;
  type: 'MATCH' | 'BETWEEN';
}

export const FilterContext = React.createContext<Filter[]>([]);
export const AddFilterContext = React.createContext<(filter: Filter) => void>(
  () => {},
);
export const RemoveFilterContext = React.createContext<(ind: number) => void>(
  () => {},
);
export const ResetFiltersContext = React.createContext<() => void>(() => {});
export const FilteredDataContext = React.createContext<ProcessedSheet | null>(
  null,
);

const mergeFiltersRemovingDuplicateValues = (f1: Filter, f2: Filter) => {
  if (f1.variable.name !== f2.variable.name || f1.type !== f2.type) {
    console.error(
      'Tried to merge filters',
      f1,
      'and',
      f2,
      'but they have different variables and/or types. Recovering by just using f2',
    );
    return f2;
  }
  const duplicateValues = intersection(f1.values, f2.values);

  return {
    ...f2,
    values: differenceBy([...f1.values, ...f2.values], duplicateValues),
    labelMap: {
      ...f1.labelMap,
      ...f2.labelMap,
    },
  };
};

const FilterProvider: React.FC<{}> = (props) => {
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const addFilter = React.useCallback(
    (filter: Filter) => {
      const sameVariableFilters = filters.filter(
        (f) => f.variable.name === filter.variable.name,
      );
      if (filter.type === 'MATCH') {
        // Add multiple values to MATCH if same filter already exists. E.g. when clicking 2 elements on the list filter.
        if (sameVariableFilters.length > 0) {
          const replaceInd = filters.findIndex(
            (f) =>
              f.type === 'MATCH' && f.variable.name === filter.variable.name,
          );
          if (replaceInd !== -1) {
            const oldFilter = filters[replaceInd];
            const newFilter = mergeFiltersRemovingDuplicateValues(
              oldFilter,
              filter,
            );
            if (newFilter.values.length > 0) {
              // New filter still has values even after removing duplicates.
              setFilters([
                ...filters.slice(0, replaceInd),
                newFilter,
                ...filters.slice(replaceInd + 1),
              ]);
            } else {
              // All values have been removed, so filter needs to be removed
              setFilters([
                ...filters.slice(0, replaceInd),
                ...filters.slice(replaceInd + 1),
              ]);
            }
            return;
          }
        }
      }
      if (
        sameVariableFilters.length > 0 &&
        sameVariableFilters.find(
          (f) => differenceBy(f.values, filter.values).length === 0,
        )
      ) {
        return; // Another filter on this variable already exists with the same properties
      }

      if (filter.type === 'BETWEEN') {
        // If there is another BETWEEN filter on this variable, replace it with this one.
        const replaceInd = filters.findIndex(
          (f) =>
            f.type === 'BETWEEN' && f.variable.name === filter.variable.name,
        );
        if (replaceInd !== -1) {
          setFilters([
            ...filters.slice(0, replaceInd),
            filter,
            ...filters.slice(replaceInd + 1),
          ]);
          return;
        }
      }

      setFilters([...filters, filter]);
    },
    [filters, setFilters],
  );
  const removeFilter = React.useCallback(
    (ind: number) => {
      setFilters([...filters.slice(0, ind), ...filters.slice(ind + 1)]);
    },
    [filters, setFilters],
  );
  const resetFilters = React.useCallback(() => {
    setFilters([]);
  }, [setFilters]);

  const data = React.useContext(DataContext);
  const filteredData = React.useMemo(() => {
    if (!data) {
      return null;
    }
    const rows = data[1];
    return [
      data[0],
      rows.filter((r) => {
        for (const filter of filters) {
          if (filter.type === 'MATCH') {
            if (
              (filter.values.map((v) =>
                v === NO_DATA_KEY ? undefined : v,
              ) as Array<undefined | number | string>).indexOf(
                r[filter.variable.name],
              ) === -1
            ) {
              return false;
            }
          } else if (
            filter.type === 'BETWEEN' &&
            (r[filter.variable.name] < filter.values[0] ||
              r[filter.variable.name] > filter.values[1])
          ) {
            return false;
          }
        }
        return true;
      }),
    ] as ProcessedSheet;
  }, [data, filters]);
  return (
    <FilterContext.Provider value={filters}>
      <AddFilterContext.Provider value={addFilter}>
        <RemoveFilterContext.Provider value={removeFilter}>
          <ResetFiltersContext.Provider value={resetFilters}>
            <FilteredDataContext.Provider value={filteredData}>
              {props.children}
            </FilteredDataContext.Provider>
          </ResetFiltersContext.Provider>
        </RemoveFilterContext.Provider>
      </AddFilterContext.Provider>
    </FilterContext.Provider>
  );
};

export default FilterProvider;
