import {Variable} from '../../data-ingest/xlsx-reader';
import {Widget, BaseWidgetSettings, BaseWidget} from '../types';
import isNumber from 'lodash/isNumber';

export interface StackedBarsBar {
  variables: Variable[];
  title: string;
}

export interface StackedBarsWidgetSettings extends BaseWidgetSettings {
  bars: StackedBarsBar[];
}

export interface StackedBarsWidget
  extends BaseWidget<StackedBarsWidgetSettings> {
  type: 'stackedbars';
}

export const canUseVariableForStackedBars = (variable: Variable) => {
  return variable.uniqueValues.every((v) => isNumber(v));
};

export const createStackedBarsWidget = (
  nextLayout: Widget['settings']['layout'],
): StackedBarsWidget => {
  return {
    type: 'stackedbars' as const,
    settings: {
      title: '',
      bars: [],
      layout: {...nextLayout, w: 6, h: 24},
    },
  };
};
