import {FieldInputProps} from 'formik';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import * as React from 'react';
import {FormFeedback, FormGroup, Input, InputProps, Label} from 'reactstrap';
import * as uuid from 'uuid';

interface Props extends InputProps {
  label?: string;
  labelClassName?: string;
  field?: FieldInputProps<boolean>;
  renderInput?: (inputProps: InputProps) => React.ReactNode;
  checked?: boolean;
  form?: any; // not sure what type this should be in a generic setting
}

const CheckFieldGroup: React.FC<Props> = (props) => {
  const {
    label,
    renderInput,
    field,
    form,
    labelClassName,
    ...inputProps
  } = props;
  const [id] = React.useState(() => `fg-${uuid.v4()}`);

  const touched: boolean =
    field && form ? get(form.touched, field.name) : false;
  const error = field && form ? get(form.errors, field.name) : undefined;
  const showError: boolean =
    field && form ? !!get(form.errors, field.name) : false;
  const allInputProps = {
    id: id,
    valid: touched ? !showError : undefined,
    invalid: touched ? showError : undefined,
    ...props.field,
    ...inputProps,
  };
  return (
    <FormGroup check>
      <Label htmlFor={id} className={labelClassName}>
        <Input
          type="checkbox"
          {...allInputProps}
          value={field?.value ? 'true' : 'false'}
          checked={field?.value}
        />
        {props.label || (field ? field.name : '')}
      </Label>
      <FormFeedback>
        {isString(error)
          ? error
          : isObject(error)
          ? Object.values(error).map((e, i) => (
              <span key={i}>{JSON.stringify(e)}</span>
            ))
          : 'Invalid'}
      </FormFeedback>
    </FormGroup>
  );
};

export default CheckFieldGroup;
