import * as React from 'react';
import {SketchPicker, ColorResult} from 'react-color';
import './color-picker-button.scss';
import {palette} from './palette';

interface Props {
  onChange: (hexValue: string) => void;
  onBlur: (hexValue: string) => void;
  value: string;
  name: string;
}

const ColorPickerButton: React.FC<Props> = (props) => {
  const {onChange, onBlur, value} = props;
  const [color, setColor] = React.useState(value);
  const handleChange = React.useCallback(
    (res: ColorResult) => {
      setColor(res.hex);
    },
    [setColor],
  );
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const handleOpen = React.useCallback(() => setDisplayColorPicker(true), [
    setDisplayColorPicker,
  ]);
  const handleClose = React.useCallback(() => {
    onChange(color);
    onBlur(color);
    setDisplayColorPicker(false);
  }, [setDisplayColorPicker, onBlur, color, onChange]);

  return (
    <div className="color-picker-button">
      <div className="swatch" onClick={handleOpen}>
        <div className="color" style={{backgroundColor: props.value}} />
      </div>
      {displayColorPicker && (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker
            color={color}
            onChangeComplete={handleChange}
            presetColors={palette}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPickerButton;

type FieldProps = Omit<Props, 'onChange'> & {
  setFieldValue: (field: string, value: string) => void;
};

export const ColorPickerButtonField: React.FC<FieldProps> = (props) => {
  const {setFieldValue, name} = props;
  const onChange = React.useCallback(
    (color: string) => {
      setFieldValue(name, color);
    },
    [setFieldValue, name],
  );
  return <ColorPickerButton {...props} onChange={onChange} />;
};
