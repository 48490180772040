import {BaseWidgetSettings, BaseWidget} from '../types';

export interface TextWidgetSettings extends BaseWidgetSettings {}

export interface TextWidget extends BaseWidget<TextWidgetSettings> {
  type: 'text';
}

export const createTextWidget = (
  nextLayout: TextWidget['settings']['layout'],
): TextWidget => {
  return {
    type: 'text' as const,
    settings: {
      title: 'Text',
      layout: {
        ...nextLayout,
        w: 3,
        h: 6,
      },
    },
  };
};
