import * as React from 'react';
import './stacked-bar.scss';
import uuid from 'uuid';
import {UncontrolledTooltip} from 'reactstrap';

export interface BarData {
  length: number; // Percentage width
  color: string;
  key?: string;
  className?: string;
  label?: string;
  value?: number;
}

interface Props {
  stacks: BarData[];
}

const StackedBar: React.FC<Props> = (props) => {
  const [id] = React.useState(() => `stacked-bar-${uuid.v4()}`);
  return (
    <div className="stacked-bar">
      {props.stacks.map((s, i) => (
        <div
          key={s.key ? s.key : i}
          id={`${id}-${i}`}
          className={`bar ${s.key ? `bar-${s.key}` : ''} bar-${i} ${
            s.className ? s.className : ''
          }`}
          style={{width: `${s.length}%`, backgroundColor: s.color}}
        >
          {s.label && (
            <UncontrolledTooltip placement="auto" target={`${id}-${i}`}>
              {s.label}
            </UncontrolledTooltip>
          )}
        </div>
      ))}
    </div>
  );
};

export default StackedBar;
